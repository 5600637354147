import React from "react";

/**
 * TranscriptMenuSecondaryData Component
 * Displays secondary information about a transcript in the sidebar menu
 */
const TranscriptMenuSecondaryData = ({ transcript_details }) => {
    return (
        (transcript_details ?
        <div className="px-3 py-1 bg-gray-600 dark:bg-gray-700 border-l-2 border-l-gray-400 dark:border-l-gray-500 shadow-lg">
            <div className="px-3 pb-1 flex justify-between ">
                <span className="text-sm text-gray-100">Title:</span>
                <span className="text-sm text-gray-100">
                {transcript_details.event_title + " Transcript"}
                </span>
            </div>
            <div className="px-3 pb-1 flex justify-between ">
                <span className="text-sm text-gray-100">Event Length:</span>
                <span className="text-sm text-gray-100">{transcript_details.audio_duration}</span>

            </div>
            {transcript_details.audio_duration ?
                <div className="px-3 pb-1 flex justify-between ">
                    <span className="text-sm text-gray-100">Q&A start:</span>
                    <span className="text-sm text-gray-100">{transcript_details.qna_start}</span>
                </div> : ""
            }

            <div className="px-3 pb-1 flex justify-between ">
                <span className="text-sm text-gray-100">Event Date:</span>
                <span className="text-sm text-gray-100">
          {new Date(transcript_details.event_date).toLocaleDateString()}
        </span>
            </div>

        </div>
                :
                <div className="px-3 py-1 bg-gray-600 dark:bg-gray-700 border-l-2 border-l-gray-400 dark:border-l-gray-500 shadow-lg">
                    <div className="px-3 pb-1 flex justify-between ">
                        <span className="text-sm text-gray-100">Title:</span>
                        <span className="text-sm text-gray-100">

                        </span>
                    </div>
                    <div className="px-3 pb-1 flex justify-between ">
                        <span className="text-sm text-gray-100">Event Length:</span>
                        <span className="text-sm text-gray-100"></span>

                    </div>


                    <div className="px-3 pb-1 flex justify-between ">
                        <span className="text-sm text-gray-100">Event Date:</span>
                        <span className="text-sm text-gray-100">

                    </span>
                    </div>

                </div>
        )
    );
};

export default TranscriptMenuSecondaryData;