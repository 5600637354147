import React from 'react';
import { FilingCardCompanySearchV2 } from '../cards';

const FilingsCardColumnDocumentSearch = ({ filingData, category, handleFilingClick }) => {
    return (
        <div className="bg-white shadow-sm rounded-md overflow-hidden">
            {filingData[category].map((filing, index) => (
                <div
                    key={filing.eid + "-" + index}
                    onClick={() => handleFilingClick(filing)}
                    className="cursor-pointer hover:bg-gray-50"
                >
                    <FilingCardCompanySearchV2 filing={filing} />
                </div>
            ))}
        </div>
    );
};

export default FilingsCardColumnDocumentSearch;