import React from 'react';
import { SearchBar } from '../../../../features/search';
import { NavBar } from '../../../../shared/components/navigation';
import { Footer } from '../../../layout';
import { LandingBackground } from '../background';

const Landing = () => {
    return (
        <>
            <NavBar/>
            <LandingBackground>
            <div className="pt-12">
                    <div className="container w-4/5 mx-auto px-4">
                        <div className="mt-12 bg-nav-bar-theme dark:bg-gray-900/80 backdrop-blur-sm border border-white/10 dark:border-gray-700/50 py-12 rounded-2xl shadow-2xl">
                            <div className="text-center max-w-3xl mx-auto px-4">
                            <div className="my-4">
                                <div className="flex items-center justify-center">
                                    <h1 className="text-7xl md:text-8xl font-bold tracking-tight">
                                        <span className="bg-gradient-to-r from-teal-400 via-blue-500 to-teal-400 bg-clip-text text-transparent">Model</span>
                                        <span className="text-white">Edge</span>
                                    </h1>
                                </div>
                                <div className="h-px w-40 mx-auto mt-8 bg-gradient-to-r from-transparent via-teal-400 to-transparent opacity-60"></div>
                            </div>
                            
                            <h2 className="text-4xl md:text-5xl text-white/90 font-bold mb-6">
                                Corporate Research Intelligence
                            </h2>

                            <p className="text-lg md:text-xl text-gray-300 mb-8 leading-relaxed">
                                Powerful AI-driven insights from SEC Filings, Transcripts, and Market Data
                            </p>

                            <div className="max-w-2xl mx-auto mb-12">
                                <SearchBar />
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-16 text-left">
                                <div className="p-4">
                                    <div className="text-teal-400 text-lg font-semibold mb-2">Real-time Analysis</div>
                                    <p className="text-gray-400">Advanced language models process corporate data instantly</p>
                                </div>
                                <div className="p-4">
                                    <div className="text-teal-400 text-lg font-semibold mb-2">Comprehensive Data</div>
                                    <p className="text-gray-400">SEC filings, transcripts, insider trades, and industry insights</p>
                                </div>
                                <div className="p-4">
                                    <div className="text-teal-400 text-lg font-semibold mb-2">Market Edge</div>
                                    <p className="text-gray-400">Get the competitive advantage with data-driven decisions</p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>

            </div>
            </LandingBackground>
            <Footer/>
        </>
    );
};

export default Landing;