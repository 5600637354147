import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axiosInstance from "../../../../shared/services/api";
import { API_URL } from "../../../../config";
import { useAuth } from "../../../auth/firebase/AuthContext";
import { useNavigate } from 'react-router-dom';

import { CheckBullet } from "../../../../shared/components/ui";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function SubscriptionTier({ tier }) {
    const { currentUser } = useAuth();
    const navigate = useNavigate();

    const handleSubscribe = async () => {
        const stripe = await stripePromise;

        if (!currentUser || !currentUser.token) {
            navigate('/login');
            return;
        }

        const headers = currentUser
            ? {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${currentUser.token}` }
            : {};

        try {
            const response = await axiosInstance.post(
                `${API_URL}/checkout/create-checkout-session`,
                { priceId: tier.stripePriceId },
                { headers }
            );

            console.log("Server response:", response.data);

            if (!response.data || !response.data.sessionId) {
                throw new Error("Invalid response from server: missing session ID");
            }

            const { sessionId } = response.data;

            console.log("Redirecting to checkout with sessionId:", sessionId);

            const result = await stripe.redirectToCheckout({
                sessionId,
            });

            if (result.error) {
                console.error("Stripe redirect error:", result.error);
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
        }
    };

    return (
        <div className={`relative transform transition-all duration-300 hover:scale-105 ${
            tier.active 
                ? "bg-gradient-to-br from-white/95 via-white/90 to-gray-50/95 dark:from-gray-800 dark:to-gray-800 backdrop-blur-sm rounded-lg shadow-xl dark:shadow-2xl dark:shadow-black/70 p-8 flex flex-col justify-between ring-2 ring-teal-400/30 dark:ring-teal-400/20" 
                : "bg-gradient-to-br from-white/80 to-gray-50/80 dark:from-gray-900 dark:to-gray-900 backdrop-blur-sm text-gray-600 dark:text-gray-200 rounded-lg shadow-xl p-8 flex flex-col justify-between pointer-events-none ring-1 ring-gray-200/50 dark:ring-gray-600/30"
        }`}>
            {tier.recommended && (
                <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                    <span className="bg-gradient-to-r from-teal-400 via-blue-500 to-teal-400 text-white px-4 py-1 rounded-full text-sm font-medium">
                        Most Popular
                    </span>
                </div>
            )}
            <div>
                <h2 className="text-2xl font-bold mb-2 text-gray-800 dark:text-white font-sans">{tier.name}</h2>
                <p className="text-4xl font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-teal-400 to-blue-500">
                    ${tier.price}/month
                </p>
                <ul className="mb-8 space-y-4">
                    {tier.features.map((feature, index) => (
                        <CheckBullet 
                            key={index} 
                            className="mb-2"
                            iconClassName={"fill-black dark:fill-white"}
                            text={feature} 
                            fill={tier.active ? 
                                (document.documentElement.classList.contains('dark') ? "#FFFFFF" : "#111827") : 
                                (document.documentElement.classList.contains('dark') ? "#D1D5DB" : "#374151")
                            }
                        />
                    ))}
                </ul>
                <p className="mb-6 text-gray-900 dark:text-white font-sans text-sm leading-relaxed">{tier.description}</p>
            </div>
            <button 
                onClick={handleSubscribe} 
                className={tier.active ? 
                    "bg-[#2dd4bf] dark:bg-[#2dd4bf]/90 hover:bg-[#14b8a6] dark:hover:bg-[#14b8a6]/90 shadow-[inset_0_1px_0_0_rgba(255,255,255,0.1)] transition-colors text-white font-semibold py-3 px-6 rounded-lg shadow-md hover:shadow-lg transition-all duration-200 ease-in-out transform hover:-translate-y-0.5" : 
                    "bg-gradient-to-r from-gray-100 to-gray-200 dark:from-gray-700 dark:to-gray-600 text-gray-400 dark:text-gray-400 ring-1 ring-gray-200 dark:ring-gray-600 font-medium py-3 px-6 rounded-lg shadow-md cursor-not-allowed"
                }
            >
                Subscribe to {tier.name}
            </button>
        </div>
    );
}

export default SubscriptionTier;