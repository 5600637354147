import React, { useState, useEffect, useRef, useCallback } from 'react';
import "../styles/TranscriptIFrame.css";
import { API_URL } from "../../../../config";
import { useNavigate } from "react-router-dom";
import { SubscriptionModal } from "../../../../features/subscription/components/modal";
import { useSubscriptionModal } from "../../../subscription/components/modal/useSubscriptionModal";

const TranscriptIFrame = ({
                           filingDoc,
                           cik,
                           document_id,
                           menuVisible,
                           style,
                           highlightSentence,
                           highlightIDs,
                           currentUser
                       }) => {

    const proxyUrl = `/api/proxy/`;
    const [iframeContent, setIframeContent] = useState('');
    const iframeRef = useRef();
    const [iframeReady, setIframeReady] = useState(false);
    const navigate = useNavigate();
    const { showSubscriptionModal, handleSubscriptionModal, handleSubscriptionModalClose } = useSubscriptionModal();
    const [url, setUrl] = useState("");
    const [isDarkMode, setIsDarkMode] = useState(false);
    const isDarkModeRef = useRef(isDarkMode);
    
    // Check if dark mode is enabled
    useEffect(() => {
        const darkModeObserver = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === 'class') {
                    const rootElement = document.documentElement;
                    const newDarkMode = rootElement.classList.contains('dark');
                    setIsDarkMode(newDarkMode);
                    isDarkModeRef.current = newDarkMode;
                }
            });
        });
        
        darkModeObserver.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ['class']
        });
        
        // Initial check
        const initialDarkMode = document.documentElement.classList.contains('dark');
        setIsDarkMode(initialDarkMode);
        isDarkModeRef.current = initialDarkMode;
        
        return () => darkModeObserver.disconnect();
    }, []);

    const fetchIframeContent = useCallback(async (forceDarkMode = null) => {
        if (!currentUser || !currentUser.token) {
            navigate('/login', {
                state: { redirectPath: `/Company/-/transcript/${document_id}` }
            });
            return;
        }

        let url = `${API_URL}${proxyUrl}${filingDoc}`;
        if (filingDoc.startsWith("/api/transcripts/")) {
            url = `${API_URL}${filingDoc}`;
        }

        setUrl(url)

        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${currentUser.token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 403) {
                handleSubscriptionModal();
            } else if (response.status === 401) {
                navigate('/login', {
                    state: { redirectPath: `/Company/-/transcript/${document_id}` }
                });
                return;
            }

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            let content = await response.text();
            
            // Add dark mode styling to the iframe content if needed
            // Use forceDarkMode parameter if provided, otherwise use ref value
            const currentDarkMode = forceDarkMode !== null ? forceDarkMode : isDarkModeRef.current;
            
            if (currentDarkMode) {
                const darkModeStyles = `
                <style id="dark-mode-styles">
                    body {
                        background-color: #1e1e1e !important; /* VSCode-style dark gray */
                        color: #f3f4f6 !important;
                    }
                    p, div, span, h1, h2, h3, h4, h5, h6, th, td {
                        color: #f3f4f6 !important;
                    }
                    a {
                        color: #93c5fd !important;
                    }
                    table, tr, td, th {
                        border-color: #4b5563 !important;
                    }
                    hr {
                        border-color: #4b5563 !important;
                    }
                    .highlight-text {
                        background-color: #fbbf24 !important;
                        color: #1f2937 !important;
                    }
                    
                    /* Fix for Quartr logo visibility in dark mode */
                    .quartr-logo svg path {
                        fill: #ffffff !important;
                        stroke-opacity: 1 !important;
                        fill-opacity: 1 !important;
                    }
                </style>
                `;
                
                // Inject the dark mode styles into the head
                if (content.includes('</head>')) {
                    content = content.replace('</head>', `${darkModeStyles}</head>`);
                } else {
                    content = `<html><head>${darkModeStyles}</head>${content}</html>`;
                }
            }
            
            setIframeContent(content);
        } catch (error) {
            console.error('Error fetching iframe content:', error);
        }
    }, [currentUser, proxyUrl, filingDoc, navigate, document_id, handleSubscriptionModal]);

    useEffect(() => {
        fetchIframeContent();
    }, [fetchIframeContent]);

    const sendMessageToIframe = useCallback((message, retryCount = 0) => {
        const iframe = iframeRef.current;
        if (iframe && iframeReady) {
            iframe.contentWindow.postMessage(message, '*');
        } else if (retryCount < 3) {
            setTimeout(() => sendMessageToIframe(message, retryCount + 1), 1000);
        } else {
        }
    }, [iframeReady]);

    const sendHighlightSentenceMessage = useCallback((nodeID, sentence) => {
        const message = {
            type: 'highlightSentence',
            sentence,
            nodeID,
        };
        sendMessageToIframe(message);
    }, [sendMessageToIframe]);

    useEffect(() => {
        if (highlightSentence) {
            const timer = setTimeout(() => {
                sendHighlightSentenceMessage(highlightIDs, highlightSentence);
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [highlightSentence, highlightIDs, sendHighlightSentenceMessage]);

    useEffect(() => {
        const iframe = iframeRef.current;
        if (!iframe) return;

        if (url === "") {
            return
        }

        const onLoad = () => {
            setIframeReady(true);

            console.log('Iframe ready, processing message queue. Url: ' + url);

            const message = {
                type: 'parentUrl',
                url: window.location.href,
                backend: API_URL + '/api/users/highlight',
                token: currentUser.token
            };

            iframe.contentWindow.postMessage(message, '*');

            //sendMessageToIframe({ type: 'currentUser', currentUser: currentUser || null });
            iframe.contentWindow.postMessage({ type: 'currentUser', currentUser: currentUser || null }, '*');

            console.log("document id: " + document_id)
            const exhibitData = {
                is_transcript: true,
                document_id: document_id,
                cik: "" + cik,
                fid: 0,
                eid: 0,
            };
            sendMessageToIframe({ type: 'exhibitData', exhibitData });

            if (window.location.hash) {
                iframe.srcdoc = iframeContent + `<script>window.location.hash = "${window.location.hash}";</script>`;
            }

            const urlParams = new URLSearchParams(window.location.search);
            const hlStart = urlParams.get('hl_start');
            const hlEnd = urlParams.get('hl_end');

            if (hlStart && hlEnd) {
                iframe.contentWindow.postMessage({ type: 'highlightParams', hlStart, hlEnd }, '*');
            }

            const divStart =urlParams.get('d_start');
            const divEnd =urlParams.get('d_end');
            if (divStart && divEnd) {
                iframe.contentWindow.postMessage({ type: 'handleHighlightDivParams', divStart, divEnd }, '*');
            }

            const sStart =urlParams.get('s_start');
            const sEnd =urlParams.get('s_end');
            if (sStart && sEnd) {
                iframe.contentWindow.postMessage({ type: 'highlightBetweenSentencesParams', sStart, sEnd }, '*');
            }
        };

        iframe.addEventListener('load', onLoad);

        return () => {
            iframe.removeEventListener('load', onLoad);
        };
    }, [iframeContent, url, currentUser, cik, document_id, sendMessageToIframe]);

    // Reload iframe content only when dark mode changes
    const previousDarkMode = useRef(isDarkMode);
    
    useEffect(() => {
        // Only refetch if dark mode has actually changed
        if (previousDarkMode.current !== isDarkMode) {
            previousDarkMode.current = isDarkMode;
            fetchIframeContent(isDarkMode);
        }
    }, [isDarkMode, fetchIframeContent]);

    return (
        <div className={`iframe-container ${!menuVisible ? "menu-hidden" : ""} ${isDarkMode ? 'dark-mode' : ''}`} style={style}>
            <iframe
                allow="clipboard-write"
                srcDoc={iframeContent}
                title="Transcript Content"
                ref={iframeRef}
                className={isDarkMode ? 'dark-iframe' : ''}
            ></iframe>
            <SubscriptionModal
                isOpen={showSubscriptionModal}
                onClose={handleSubscriptionModalClose}
                pageMessage={"Transcripts available under our Basic subscription."}
                subscriptionRequired={"Basic"}
            />
        </div>
    );
};

export default TranscriptIFrame;