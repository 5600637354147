import React from 'react';

const LandingProductBackground = ({ children, className }) => {
    return (
        <div className={`bg-cover bg-center w-full ${className}`}
             style={{
                 backgroundImage: "url('/hero-background-2.jpg')",
                 backgroundSize: 'cover',
             }}
        >
            {children}
        </div>
    );
};

export default LandingProductBackground;