import React from 'react';
import { useNavigate } from 'react-router-dom';

const CompanyTickerBubbleField = ({ companies }) => {
    const navigate = useNavigate();

    const handleButtonClick = (ticker) => {
        navigate(`/company/${ticker[0]}`);
    };

    if (!companies || !Array.isArray(companies)) { // Render nothing if companies is not an array
        return null;
    }

    return (
        <>
            {companies.map((company) => (
                <div key={company.cik} className="relative group m-0.5">
                    {
                        Array.isArray(company.ticker) &&
                        <>
                            <button
                                className="bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 text-gray-800 dark:text-gray-200 font-medium py-1.5 px-4 rounded text-sm transition-colors duration-150 ease-in-out font-sans tracking-wide"
                                onClick={() => handleButtonClick(company.ticker)}
                            >
                                {company.ticker[0]}
                            </button>
                            <div className="absolute hidden group-hover:block bottom-full mb-1 pointer-events-none">
                                <div className="bg-gray-800 dark:bg-gray-700 text-white text-xs rounded-md py-1.5 px-2.5 whitespace-nowrap shadow-lg border border-gray-700/20 dark:border-gray-500/20 backdrop-blur-sm">
                                    {company.name}
                                </div>
                                <div className="w-2 h-2 bg-gray-800 dark:bg-gray-700 transform rotate-45 absolute -bottom-1 left-1/2 -translate-x-1/2 border-r border-b border-gray-700/20 dark:border-gray-500/20"></div>
                            </div>
                        </>
                        }
                </div>
            ))}
        </>
    );
};

export default CompanyTickerBubbleField;