import React, { useEffect, useState } from 'react';
import axiosInstance from "../../../../shared/services/api";
import { API_URL } from "../../../../config";

const UserSubscriptionDetailsSmall = ({ currentUser }) => {
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (currentUser) {
            const headers = { Authorization: `Bearer ${currentUser.token}` };
            setLoading(true);
            axiosInstance.get(`${API_URL}/api/users/subscription/info`, { headers })
                .then(response => {
                    setSubscriptionData(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                    setError('Failed to fetch subscription data');
                    setLoading(false);
                });
        }
    }, [currentUser]);

    if (loading) {
        return <div className="text-sm text-gray-600 dark:text-gray-400">Loading subscription info...</div>;
    }

    if (error) {
        return <div className="text-sm text-red-500">Error: {error}</div>;
    }

    const hasActiveSubscription = subscriptionData && 
                                 Array.isArray(subscriptionData) && 
                                 subscriptionData.length > 0 &&
                                 subscriptionData.some(sub => sub.active);

    return (
        <div className="text-sm">
            <div className="flex items-center space-x-1">
                <span className={`h-2 w-2 rounded-full ${hasActiveSubscription ? 'bg-green-500' : 'bg-red-500'}`}></span>
                <span className="text-gray-700 dark:text-gray-300">
                    {hasActiveSubscription ? 'Pro' : 'Free'} Plan
                </span>
            </div>
        </div>
    );
};

export default UserSubscriptionDetailsSmall;