import React from 'react';
import { NavBarSmall } from "../../../../shared/components/navigation";
import { FooterSmall } from "../../../layout";
import { RecentFilings } from './';
import { useParams } from "react-router-dom";

function RecentFilingsFeed() {
    const { n } = useParams();

    return (
        <div className="bg-gray-300 bg-cover bg-center min-h-screen">
            <NavBarSmall />
            <div className="flex flex-col bg-gray-300 min-h-screen">
                <div className="text-center bg-cover p-2">
                    <div className="w-full bg-company-data-background pb-8">
                        <div className="md:w-3/5 w-11/12 mx-auto pt-4">
                            <RecentFilings portfolioName={"empty"} n={n} />
                        </div>
                    </div>
                </div>
            </div>
            <FooterSmall/>
        </div>
    );
}

export default RecentFilingsFeed;