import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider as DarkModeProvider } from './contexts/ThemeContext';
import { Portfolio } from './features/portfolio';
import { Login, LoginEmail } from './features/auth';
import { AuthProvider } from './features/auth';
import { Home, Landing, LandingProducts } from './features/landing';
import { Company, CompanyDocumentSearch } from './features/company';
import { SECFiling, RecentFilingsFeed } from './features/filings';
import { Transcript } from './features/transcripts';
import { TableSearchPage, CompanyTableSearchFilings } from './features/search';
import { Profile, CancelSubscriptionProfile } from './features/profile';
import { About, Privacy, Terms } from './features/legal';
import { Highlights } from './features/highlights';
import { RecentFilingsFeedIndustryByTicker } from './features/industry';
import { NotFoundPage } from './features/error';
import { CompanyInsiderTG } from './features/insiders';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

function App() {

  return (
      <QueryClientProvider client={queryClient}>
      <DarkModeProvider>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <Router>
              <div className="min-h-screen bg-white dark:bg-gray-900 dark:bg-opacity-100 text-gray-900 dark:text-gray-100">
              <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/landing" element={<Landing />} />
              <Route path="/subscriptions" element={<LandingProducts />} />
              <Route path="/login" element={<Login />} />
              <Route path="/login/email" element={<LoginEmail />} />

              <Route path="/account" element={<Profile />}/>
              <Route path="/account/actions/unsubscribe" element={<CancelSubscriptionProfile />}/>
              <Route path="/highlights" element={<Highlights />}/>
              <Route path="/recent" element={<RecentFilingsFeed />}/>
              <Route path="/recent/:n" element={<RecentFilingsFeed />}/>
              <Route path="/about" element={<About />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/about" element={<About />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/industry/filings/company/:ticker" element={<RecentFilingsFeedIndustryByTicker />} />

              <Route path="/company/:ticker" element={<Company />} />
              <Route path="/company/insider/:ticker" element={<CompanyInsiderTG />} />
              <Route path="/company/search/:ticker" element={<CompanyDocumentSearch />} />
              <Route path="/tables/:ticker/:table_id" element={<TableSearchPage />} />
              <Route path="/filings/tables/:ticker/:table_id" element={<CompanyTableSearchFilings />} />
              <Route path="/company/:ticker/filing/:type/:accession_num" element={<SECFiling />} />
              <Route path="/company/:ticker/transcript/:document_id" element={<Transcript />} />
              <Route path="/company/:ticker/filing/:type/:accession_num/exhibit/:exhibit_num" element={<SECFiling />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>

              </div>
            </Router>
          </AuthProvider>
        </ThemeProvider>
      </DarkModeProvider>
      </QueryClientProvider>
  );
}

export default App;