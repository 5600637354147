import React, { useState } from 'react';
import './Arrow.css';

/**
 * Consolidated Arrow component for toggling menu visibility
 * Supports multiple variants for different use cases
 * 
 * @param {Object} props - Component props
 * @param {Function} props.onClick - Click handler function
 * @param {boolean} [props.initialState=false] - Initial open/closed state
 * @param {string} [props.variant='default'] - Arrow style variant ('default', 'v1', 'v2', 'table', 'filing')
 * @param {string} [props.className] - Additional CSS classes
 * @returns {JSX.Element} Arrow component
 */
const Arrow = ({ onClick, initialState = false, variant = 'default', className = '' }) => {
    const [isOpen, setIsOpen] = useState(initialState);

    const handleClick = () => {
        setIsOpen(!isOpen);
        if (onClick) onClick();
    };
    
    // Simple variant (v1) with CSS styling
    if (variant === 'v1') {
        return (
            <button
                className={`arrow-btn ${isOpen ? 'drawer-open' : ''} ${className}`}
                onClick={handleClick}
            />
        );
    }
    
    // Arrow v2 variant
    if (variant === 'v2') {
        return (
            <button
                className={`absolute top-3 -right-2 z-10 bg-white dark:bg-gray-800 rounded-full shadow-md w-7 h-7 flex items-center justify-center cursor-pointer ${className}`}
                onClick={handleClick}
            >
                <svg 
                    className={`w-4 h-4 text-gray-600 dark:text-gray-300 transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`} 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </button>
        );
    }
    
    // Table search variant
    if (variant === 'table') {
        return (
            <div 
                onClick={handleClick}
                className={`absolute p-3 top-24 mt-2 border-2 border-gray-200 dark:border-gray-600 -right-1 rounded-r-xl rounded-b-xl cursor-pointer bg-white dark:bg-gray-700 hover:shadow-sm transition-all duration-300 ${className}`}
            >
                <svg 
                    className={`w-5 h-5 text-gray-500 dark:text-gray-300 transition-transform duration-300 ${isOpen ? '' : 'rotate-180'}`} 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
            </div>
        );
    }
    
    // Filing menu variant (migrated from filings/components/menu/Arrow.js)
    if (variant === 'filing') {
        return (
            <button
                className={`arrow-btn2 ${isOpen ? 'open' : 'closed'} ${className}`}
                onClick={handleClick}
                aria-label={isOpen ? 'Close menu' : 'Open menu'}
            >
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
                >
                    <path
                        d="M15 18L9 12L15 6"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
        );
    }
    
    // Default variant (modern)
    return (
        <button
            className={`absolute top-3 right-[-12px] z-10 bg-transparent border-none cursor-pointer p-1 transition-all duration-300 ease-in-out rounded w-10 h-10 flex items-center justify-center hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:shadow-outline ${isOpen ? 'open' : 'closed'} ${className}`}
            onClick={handleClick}
            aria-label={isOpen ? 'Close menu' : 'Open menu'}
        >
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`transition-transform duration-300 ease-in-out text-gray-700 dark:text-gray-300 ${isOpen ? 'rotate-180' : 'rotate-0'}`}
            >
                <path
                    d="M15 18L9 12L15 6"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </button>
    );
};

export default Arrow;