import React from "react";
import { theme } from "../../../../theme"

function FilingCardCompanySearch({ filing = {}, exhibit = {}, isLastItem = false, numItems = 10 }) {
    const getRelativeDate = (date) => {
        if (!date || isNaN(date.getTime())) return null;
        
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        // Create copies of the dates to avoid modifying originals
        const dateToCheck = new Date(date);
        const todayToCheck = new Date(today);
        const yesterdayToCheck = new Date(yesterday);
        
        if (dateToCheck.setHours(0, 0, 0, 0) === todayToCheck.setHours(0, 0, 0, 0)) {
            return "Today";
        } else if (dateToCheck.setHours(0, 0, 0, 0) === yesterdayToCheck.setHours(0, 0, 0, 0)) {
            return "Yesterday";
        } else {
            return null;
        }
    };

    const formatFilingDate = (dateString) => {
        if (!dateString) return "N/A";
        
        try {
            const date = new Date(dateString);
            if (isNaN(date.getTime())) return "N/A";
            
            const year = date.getFullYear().toString().substr(-2);
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            const day = ("0" + date.getDate()).slice(-2);

            return `${month}/${day}/${year}`;
        } catch (e) {
            return "N/A";
        }
    };

    // Handle both camelCase and snake_case properties
    const read = filing.read ?? false;
    
    // Get filing date, handling different property names
    const filingDate = filing.filing_date || filing.filingDate;
    
    // Get filing type, handling different property names
    const filingType = filing.filing_type || filing.filingType || "N/A";
    
    // Get title, handling different property names
    const title = filing.title || "No title";

    let cssClasses = `${isLastItem ? "border-b-0 border-gray-400" : "border-gray-400"} ${read ? "text-gray-500" : ""
    }`;

    // Parse date safely
    let filingDateObj = null;
    try {
        if (filingDate) {
            filingDateObj = new Date(filingDate);
        }
    } catch (e) {
        filingDateObj = null;
    }
    
    const relativeDate = filingDateObj ? getRelativeDate(filingDateObj) : null;
    let dateLabelClass = "text-xs mt-1 py-0.5 px-2 rounded-full";
    if (relativeDate) {
        dateLabelClass += ` ${relativeDate === "Today"
            ? "bg-green-600 text-grey-900"
            : "bg-amber-400 text-grey-900"
        }`;
    }

    return (
        <div className={`${theme.colors.openDataBackground} hover:${theme.colors.openDataBackgroundHover} mb-0 ${cssClasses} py-0 px-1 m-0 border-t`}>
            <div className="flex flex-row justify-between items-center w-full m-1 rounded-sm">
                <div className="flex items-center gap-2 px-1 text-xs justify-between w-full round">
                    <span className="font-roboto font-bold w-14 ml-1 text-gray-200">
                        {filingType}
                    </span>
                    <span className="text-gray-400">|</span>
                    <div className="font-roboto overflow-ellipsis overflow-hidden whitespace-nowrap w-36 flex-grow text-gray-200" title={title}>
                        {title}
                    </div>
                    <div className="font-roboto flex flex-col items-end justify-end">
                        <span className="text-xs text-gray-200">
                            {formatFilingDate(filingDate)}
                        </span>
                        {relativeDate && (
                            <span className={dateLabelClass}>{relativeDate}</span>
                        )}
                    </div>
                </div>
            </div>
            <div className="icon"></div>
        </div>
    );
}

export default FilingCardCompanySearch;