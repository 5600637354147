import React, { useState, useEffect } from "react";
import axiosInstance from '../../../../shared/services/api';
import { API_URL } from "../../../../config";
import { PortfolioIndustryColumn } from "../../../../features/portfolio";
import { CompanyTickerRow } from "../../../../features/company/components/ticker";

function RecentFilingsIndustryGroupByTicker({ ticker }) {
    const [portfolio, setPortfolio] = useState(null);

    useEffect(() => {
        async function fetchEmptyPortfolioFill() {
            try {
                const res = await axiosInstance.get(`${API_URL}/api/company/facts/industry/filings/${ticker}`);
                console.log("API response:", res.data);
                console.log("Length:", res.data.length);
                console.log("isArray:", Array.isArray(res.data));
                setPortfolio(res.data);
            } catch (error) {
                console.error("Error fetching main portfolio:", error);
            } finally {
                console.log("finally")
            }
        }
        fetchEmptyPortfolioFill();
    }, [ticker]);


    return (
        <div className="flex flex-col min-h-screen bg-gray-50 dark:bg-gray-900 px-4 py-6">
            <div className="mb-6">
                {portfolio && portfolio.companies && <CompanyTickerRow companies={portfolio.companies} />}
            </div>
            <div className="flex-grow">
                {portfolio && portfolio.filings && <PortfolioIndustryColumn filings={portfolio.filings} ticker={ticker} />}
            </div>
        </div>
    );
}

export default RecentFilingsIndustryGroupByTicker;