import React, { useState, useEffect, useCallback } from 'react';
import { API_URL } from "../../../../config";
import axiosInstance from '../../../../shared/services/api';
import { useNavigate } from "react-router-dom";
import { formatCurrency, formatNumber } from "../../../../shared/utils/values";
import { ChevronDownIcon, ChevronUpIcon, DocumentTextIcon } from '@heroicons/react/solid';
import DownArrowIcon from '../../../../shared/assets/down-arrow.svg';
import { InsiderInfoCell, InsiderDateCell, ValueCell } from "../table";
import { SummarizeButton } from "../buttons";
import { Spinner } from "../../../../shared/components/ui";

const TransactionClassification = ({ classification }) => {
    const firstWord = classification.split(/[,\s]/)[0];

    return (
        <td className="pr-1 dark:text-gray-200">
            <div className="md:w-44 overflow-hidden text-ellipsis whitespace-nowrap" title={classification}>
                <span className="hidden md:inline">{classification}</span>
                <span className="md:hidden">{firstWord}</span>
            </div>
        </td>
    );
};

const InsiderTransactionsGroup = ({ ticker, currentUser }) => {
    const [transactions, setTransactions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedRows, setExpandedRows] = useState({});
    const [offset, setOffset] = useState(0);
    const navigate = useNavigate();

    const fetchTransactions = useCallback(async (more = false) => {
        if (!ticker || !currentUser?.token) return;
        setIsLoading(true);
        const nextOffset = more ? offset : 0;
        try {
            const response = await axiosInstance.get(`${API_URL}/api/company/insider-trades/${ticker}?limit=100&start=${nextOffset}&types=`, {
                headers: { Authorization: `Bearer ${currentUser.token}` },
            });

            if (more) {
                setTransactions(prevTransactions => [...prevTransactions, ...response.data.transactions]);
                setOffset(prevOffset => prevOffset + response.data.transactions.length);
            } else {
                setTransactions(response.data.transactions);
                setOffset(response.data.transactions.length);
            }
        } catch (err) {
            setError(err.toString());
        } finally {
            setIsLoading(false);
        }
    }, [ticker, currentUser?.token, offset]);


     useEffect(() => {
        fetchTransactions()
            .then((r) => {
                // Handle the success case if needed
            })
            .catch((error) => {
                // Handle the error case
                console.error('Error fetching transactions:', error);
            });
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticker, currentUser?.token]);

    const handleRowClick = async (transactionId) => {
        if (expandedRows[transactionId]) {
            setExpandedRows({ ...expandedRows, [transactionId]: false });
        } else {
            const url = `${API_URL}/api/company/insider-trades/individual-trades/${transactionId}`;
            console.log("Fetching url" + url);
            try {
                const response = await axiosInstance.get(url);
                setExpandedRows({ ...expandedRows, [transactionId]: response.data.transactions });
            } catch (error) {
                console.error("Failed to fetch transaction details", error);
            }
        }
    };

    if (isLoading && !transactions.length) return (
        <div className="flex justify-center items-center min-h-screen">
            <Spinner />
        </div>
    );

    if (error) return <div></div>;

    return (
        <div className="w-full mx-auto">
            <div className="bg-cover bg-center min-h-screen">
                <div className="mx-auto md:p-4 md:pt-2 p-0 pt-1">
                    <table className="w-full text-xs transition-opacity duration-300 overflow-anchor-none bg-cover border border-gray-300 dark:border-gray-700 rounded-sm shadow-sm dark:text-gray-200">
                        <thead>
                        <tr className="text-left bg-gray-100 dark:bg-gray-700">
                            <th className="px-1 pl-2 py-1 dark:text-gray-200">Date</th>
                            <th className="dark:text-gray-200">Owner</th>
                            <th className="dark:text-gray-200">Type <span className="font-normal text-gray-400 dark:text-gray-500">(filtered)</span></th>
                            <th className="hidden sm:table-cell dark:text-gray-200">Security</th>
                            <th className="text-right hidden sm:table-cell dark:text-gray-200">Price</th>
                            <th className="text-right hidden sm:table-cell dark:text-gray-200">Units</th>
                            <th className="text-right hidden sm:table-cell dark:text-gray-200">Value</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>

                        <tbody >
                        {transactions.map((transaction) => {
                            const isExpanded = !!expandedRows[transaction.id];
                            return (
                                <React.Fragment key={transaction.id}>
                                    <tr
                                        className={`${transaction.OpenMarket === false ? 'bg-white dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700' : transaction.PurchaseOrSale === 'Disposal' ? 'bg-red-200 dark:bg-red-900/50 hover:bg-red-300 dark:hover:bg-red-900' : 'bg-green-200 dark:bg-green-900/50 hover:bg-green-300 dark:hover:bg-green-900'} border-y border-gray-300 dark:border-gray-600 shadow-sm hover:shadow-md`}
                                        // onClick={() => handleRowClick(transaction.id)}
                                    >
                                        <InsiderDateCell date={transaction.LatestTransactionDate} ></InsiderDateCell>
                                        <InsiderInfoCell ownerCIK={transaction.OwnerCIK} ownerName={transaction.OwnerName} ownerRelationship={transaction.OwnerRelationship} ></InsiderInfoCell>

                                        <TransactionClassification classification={transaction.Classification} />
                                        <td className="pr-1 hidden sm:table-cell dark:text-gray-200">
                                            <div className="md:w-36 w-20 overflow-hidden text-ellipsis whitespace-nowrap">{transaction.SecurityName}</div>
                                        </td>

                                        <td className="text-right px-2 hidden sm:table-cell dark:text-gray-200">
                                            {typeof transaction.DisplayPrice === 'number' ? formatCurrency(transaction.DisplayPrice, 2, "$") : ''}
                                        </td>

                                        <td className="text-right px-1 hidden sm:table-cell dark:text-gray-200">{formatNumber(transaction.DisplayShares, 0)}</td>
                                    <ValueCell value={transaction.DisplayValue}></ValueCell>
                                    <td className="w-9 p-0 text-center">
                                        <a href={`/filing/${transaction.id}/1?cik=${transaction.IssuerCIK}`}>
                                            <span className="icon-doc-text dark:text-gray-400"></span>
                                        </a>
                                    </td>
                                    <td className="w-9 p-0 text-center">
                                        <button type="button" onClick={() => navigate(`/company/${ticker}/filing/-/${transaction.id}`)}>
                                            <DocumentTextIcon className="h-4 w-4 ml-1 text-gray-500 dark:text-gray-400" />
                                        </button>
                                    </td>
                                    <td className="w-9 p-0 text-center">
                                            <SummarizeButton className="h-4 w-4 ml-1 text-gray-500 dark:text-gray-400" fid={transaction.id} currentUser={currentUser}/>
                                    </td>
                                    <td className="w-9 p-0 text-center" >
                                        <button type="button" onClick={() => handleRowClick(transaction.id)}>
                                            {isExpanded ? <ChevronUpIcon className="h-4 w-4 dark:text-gray-400" /> : <ChevronDownIcon className="h-4 w-4 dark:text-gray-400" />}
                                        </button>
                                    </td>
                                    </tr>
                                    {isExpanded && expandedRows[transaction.id].map((detail) => (
                                        <tr key={detail.id} className={`bg-gray-100 dark:bg-gray-700 text-xs text-gray-600 dark:text-gray-300 expand-transition`}>
                                                <td className="px-1 pl-2 py-1">{new Date(detail.TransactionDate).toLocaleDateString('en-US', {
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                    year: 'numeric'
                                                })}</td>

                                            <td className={`max-w-sm text-ellipsis whitespace-nowrap group relative ${detail.footnotes.OwnershipNature?.length > 0 ? 'bg-gray-200 dark:bg-gray-600 footnote-indicator ' : ''}`}>
                                                {detail.OwnerName}
                                                {detail.footnotes.OwnershipNature?.map((note, index) => (
                                                    <div key={index} className="absolute hidden w-54 whitespace-pre-wrap group-hover:block bg-code-near-black text-white text-xs rounded py-1 px-2 bottom-full mb-1">
                                                        {note.Text}
                                                    </div>
                                                ))}
                                                <span className="text-gray-500 ml-2">{detail.OwnerRelationship}</span>
                                            </td>
                                            <td className={`w-32 text-ellipsis whitespace-nowrap pr-1 group relative ${detail.footnotes.TransactionCode?.length > 0 ? 'bg-gray-200 dark:bg-gray-600 footnote-indicator ' : ''}`}>
                                                {detail.Type}
                                                {detail.footnotes.TransactionCode?.map((note, index) => (
                                                    <div key={index} className="absolute hidden w-72 whitespace-pre-wrap group-hover:block bg-code-near-black text-white text-xs rounded py-1 px-2 bottom-full mb-1">
                                                        {note.Text}
                                                    </div>
                                                ))}
                                            </td>

                                            <td className={`group pr-1 relative ${(detail.footnotes.ExerciseDate?.length > 0) || (detail.footnotes.SecurityTitle?.length > 0)  ? 'bg-gray-200 dark:bg-gray-600 footnote-indicator ' : ''}`}>
                                                <div className={"md:w-36 w-20 text-ellipsis whitespace-nowrap "}>{detail.SecurityTitle}</div>

                                                {detail.footnotes.ExerciseDate?.length > 0 &&
                                                    <div className="absolute hidden w-54 whitespace-pre-wrap group-hover:block bg-code-near-black text-white text-xs rounded py-1 px-2 bottom-full mb-1">
                                                        {detail.footnotes.ExerciseDate.map((note, index) => (
                                                            <div key={index}>{note.Text}</div>
                                                        ))}
                                                    </div>
                                                }
                                                {detail.footnotes.SecurityTitle?.length > 0 &&
                                                    <div className="absolute hidden w-54 whitespace-pre-wrap group-hover:block bg-code-near-black text-white text-xs rounded py-1 px-2 bottom-full mb-1">
                                                        {detail.footnotes.SecurityTitle.map((note, index) => (
                                                            <div key={index}>{note.Text}</div>
                                                        ))}
                                                    </div>
                                                }

                                            </td>
                                                <td className={`max-w-sm text-right group relative px-2 ${detail.footnotes.PricePerShare?.length > 0 ? 'bg-gray-200 dark:bg-gray-600 footnote-indicator ' : ''}`}>
                                                    {typeof detail.PricePerShare === 'number' ? formatCurrency(detail.PricePerShare, 2, "$") : ''}
                                                    {detail.footnotes.PricePerShare?.map((note, index) => (
                                                        <div key={index} className="absolute hidden w-72 whitespace-pre-wrap group-hover:block bg-code-near-black text-white text-xs rounded py-1 px-2 bottom-full mb-1">
                                                            {note.Text}
                                                        </div>
                                                    ))}
                                                </td>

                                                <td className={`max-w-sm text-right group relative px-1 ${detail.footnotes.TransactionAmount?.length > 0 ? 'bg-gray-200 dark:bg-gray-600 footnote-indicator ' : ''}`}>
                                                    {formatNumber(detail.Shares, 0)}
                                                    {detail.footnotes.TransactionAmount?.map((note, index) => (
                                                        <div key={index} className="absolute hidden w-72 whitespace-pre-wrap group-hover:block bg-code-near-black text-white text-xs rounded py-1 px-2 bottom-full whitespace-nowrap mb-1">
                                                            {note.Text}
                                                        </div>
                                                    ))}
                                                </td>

                                                <td className="text-right">
                                                    {typeof detail.NotionalValue === 'number' ? formatCurrency(detail.NotionalValue, 0, "$") : ''}
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                        <tr onClick={() => fetchTransactions(true)} className="bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 footnote-indicator cursor-pointer">
                            <td colSpan="100%" className="text-center py-2">
                                <img src={DownArrowIcon} alt="Load More" className="inline-block w-3 h-3 mr-2" />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default InsiderTransactionsGroup;