import React, { useEffect } from 'react';
import { NavBarSmall } from '../../../../shared/components/navigation';
import { Footer } from '../../../layout';
import { LandingProductBackground } from '../background';
import { SubscriptionTier } from '../../../../features/subscription';
import { subscriptionPlans } from '../../../subscription/subscriptions/subscriptionPlans';

const LandingProducts = () => {
    useEffect(() => {
        document.title = "Subscribe | Modeledge";
    }, []);

    return (
        <>
            <NavBarSmall />
            <LandingProductBackground className="flex-grow">
                <div className="container mx-auto px-4 py-4">
                    <div className="mt-8 mb-16 flex flex-col md:flex-row justify-center items-stretch space-y-8 md:space-y-0 md:space-x-8">
                        <SubscriptionTier tier={subscriptionPlans.basic} />
                        <SubscriptionTier tier={subscriptionPlans.pro} />
                    </div>
                </div>
            </LandingProductBackground>
            <Footer />
        </>
    );
};

export default LandingProducts;