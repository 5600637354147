import React from 'react';
import { format, parseISO } from 'date-fns';

const FilingCardCompanySearch = ({ filing, exhibit }) => {
    // Format the date
    const formattedDate = filing?.filing_date 
        ? format(parseISO(filing.filing_date), 'MMM d, yyyy')
        : 'No date';
        
    // Determine form type and description based on filing and exhibit
    // If we have a filing, use its form_type, otherwise show Unknown Form
    const formType = filing?.filing_type;
    
    // For description, first try to use the filing description
    // If exhibit is provided, we use its description
    let description = filing?.title || '';
    if (exhibit && exhibit.exhibit_number) {
        // If we have both a filing description and an exhibit, combine them
        description = description 
            ? `${description} (Exhibit ${exhibit.exhibit_number}${exhibit.description ? ': ' + exhibit.description : ''})`
            : `Exhibit ${exhibit.exhibit_number}${exhibit.description ? ': ' + exhibit.description : ''}`;
    }
    
    // If we still don't have a description, use the default text
    if (!description) {
        description = '';
    }

    return (
        <div className="px-3 pt-1 pb-1.5 border-b border-gray-200 dark:border-gray-700 last:border-b-0 flex items-center">
            <div className="w-16 flex-shrink-0">
                <span className="text-xs font-medium text-gray-900 dark:text-gray-100">{formType}</span>
            </div>
            <div className="flex-1 px-2 truncate">
                <span className="text-xs text-gray-500 dark:text-gray-400">{description}</span>
            </div>
            <div className="flex-shrink-0 flex items-center gap-2">
                <span className="text-xs text-gray-500 dark:text-gray-400 whitespace-nowrap">{formattedDate}</span>
                <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-blue-100 dark:bg-blue-800 text-blue-800 dark:text-blue-100">
                    View
                </span>
            </div>
        </div>
    );
};

export default FilingCardCompanySearch;