import EventSourcePolyfill from 'eventsource-polyfill';
import { useState, useEffect, useCallback } from 'react';

const useSSEPolyfill = (url, token) => {
    const [data, setData] = useState('');
    const [error, setError] = useState(null);
    const [isComplete, setIsComplete] = useState(false);

    const processData = useCallback((eventData) => {
        try {
            const parsedData = JSON.parse(eventData);
            if (typeof parsedData === 'object' && parsedData !== null) {
                if (parsedData.type === 'update' && parsedData.data) {
                    return parsedData.data;
                } else if (parsedData.type === 'complete') {
                    setIsComplete(true);
                    return '';
                } else {
                    return JSON.stringify(parsedData);
                }
            } else {
                return String(parsedData);
            }
        } catch (e) {
            return eventData;
        }
    }, []);

    useEffect(() => {
        if (!url || !token) return;

        const eventSource = new EventSourcePolyfill(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        eventSource.onmessage = (event) => {
            const processedData = processData(event.data);
            setData(prevData => prevData + processedData);
        };

        eventSource.onerror = (error) => {
            setError(error);
            eventSource.close();
        };

        return () => {
            eventSource.close();
        };
    }, [url, token, processData]);

    return { data, error, isComplete };
};

export default useSSEPolyfill;