import React from "react";
import { useNavigate } from 'react-router-dom';

function CompanyTickerRow({ companies }) {
    const navigate = useNavigate();
    const handleButtonClick = (ticker) => {
        navigate(`/company/${ticker[0]}`);
    };

    return (
        <div className="flex flex-row flex-wrap">
            {companies.map((company) => (
                <div key={company.cik} className="relative m-1 group">
                    <button
                        className="bg-code-near-black hover:bg-green-600 text-white font-bold py-1 px-2 rounded text-sm border border-gray-400"
                        onClick={() => handleButtonClick(company.ticker)}
                    >
                        {Array.isArray(company.ticker) ? company.ticker.join(', ') : company.ticker || ''}
                    </button>
                    <span className="absolute hidden group-hover:block bg-gray-700 text-white text-xs rounded py-1 px-2 bottom-full whitespace-nowrap mb-1">
                        {company.name}
                    </span>
                </div>
            ))}
        </div>
    );
}

export default CompanyTickerRow;