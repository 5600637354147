import React from "react";
import { format, isToday, isYesterday, isValid } from 'date-fns';
import * as stringUtils from '../../../../shared/utils/strings';

function FilingCardCompanySearch({ filing = {}, isLastItem = false, allSmall = false }) {
    const getRelativeDate = (date) => {
        if (!isValid(date)) return null;
        if (isToday(date)) return "Today";
        if (isYesterday(date)) return "Yesterday";
        return null;
    };

    const formatFilingDate = (dateString) => {
        try {
            const date = new Date(dateString);
            if (!isValid(date)) return "N/A";
            return format(date, 'MM/dd/yy');
        } catch (e) {
            console.error("Error formatting date:", e);
            return "N/A";
        }
    };

    // Handle both camelCase and snake_case properties
    const read = filing.read ?? false;
    
    // Get filing date, handling different property names
    const filingDate = filing.filing_date || filing.filingDate;
    let date = null;
    let relativeDate = null;
    
    if (filingDate) {
        try {
            date = new Date(filingDate);
            relativeDate = getRelativeDate(date);
        } catch (e) {
            console.error("Error parsing date:", e);
        }
    }
    
    // Get filing type, handling different property names
    const filingType = filing.filing_type || filing.filingType || "N/A";
    
    // Get title, handling different property names
    const title = filing.title || "No title";
    
    // Process title based on filing type
    const processedTitle = filingType === "8-K"
        ? stringUtils.shortenStringByRemovingAnnounces(title)
        : title;

    return (
        <div
            className={`
                flex items-center w-full px-2 py-1.5
                ${isLastItem ? 'border-b' : ''} border-t border-gray-200 dark:border-gray-700
                ${read ? 'bg-gray-50 dark:bg-gray-800' : 'bg-white dark:bg-gray-800'}
                hover:bg-blue-50 dark:hover:bg-gray-700 transition-colors duration-150 ease-in-out
            `}
        >
            {allSmall ?
                <div className="w-10 shrink-0 font-medium text-xs text-gray-600 dark:text-gray-400 truncate">
                    {filingType}
                </div>
                :
                <div className="w-16 shrink-0 font-medium text-xs text-gray-600 dark:text-gray-400 truncate">
                    {filingType}
                </div>
            }

            <div className="flex-grow px-2 min-w-0">
                <p className="text-xs text-gray-800 dark:text-gray-200 truncate" title={title}>
                    {processedTitle}
                </p>
            </div>
            <div className="flex flex-col items-end shrink-0">
                <span className="text-xs text-gray-500 dark:text-gray-400 whitespace-nowrap">
                    {filingDate ? formatFilingDate(filingDate) : "N/A"}
                </span>
                {relativeDate && (
                    <span className={`
                        text-[10px] mt-0.5 py-0.5 px-1.5 rounded-full whitespace-nowrap
                        ${relativeDate === "Today"
                        ? "bg-emerald-100 dark:bg-emerald-900 text-emerald-700 dark:text-emerald-200"
                        : "bg-amber-100 dark:bg-amber-900 text-amber-700 dark:text-amber-200"}
                    `}>
                        {relativeDate}
                    </span>
                )}
            </div>
        </div>
    );
}

export default FilingCardCompanySearch;