import React, { useEffect, useState } from 'react';
import { useAuth } from "../../auth/firebase/AuthContext";
import axiosInstance from '../../../shared/services/api';
import { API_URL } from "../../../config";
import { arrayToStringWithCommasAndLengthLimitIntelligent } from "../../../shared/utils/values";
import { formatDate } from "../../../shared/utils/dates";
import { NavBarSmall } from '../../../shared/components/navigation';
import { Footer } from '../../layout';

const HighlightCompanyName = ({ name, tickers }) => (
    <span className="text-gray-900 dark:text-gray-100 font-bold">
    {name} ({arrayToStringWithCommasAndLengthLimitIntelligent(tickers)})
  </span>
);

const Highlights = () => {
    const { currentUser } = useAuth();
    const [highlightData, setHighlightData] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);

    useEffect(() => {
        if (currentUser) {
            const headers = { Authorization: `Bearer ${currentUser.token}` };
            axiosInstance.get(`${API_URL}/api/users/highlights/all`, { headers })
                .then(response => {
                    setHighlightData(response.data);
                    if (response.data.length > 0) {
                        setSelectedCompany(response.data[0].company.cik);
                    }
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                });
        }
    }, [currentUser]);

    const renderHighlight = (highlight) => (
        <div key={highlight.id} className="py-1 px-2 border border-gray-400 dark:border-gray-600 bg-gray-200 dark:bg-gray-700 rounded-sm mb-3">
            <div className="py-1 font-bold">
                <HighlightCompanyName name={highlight.company.name} tickers={highlight.company.ticker} />
                {highlight.documentType === "filing" ? (
                    <p className="text-xs font-normal my-1 text-gray-900 dark:text-gray-100">
                        {highlight.filing.filing_type} - {highlight.filing.title || highlight.exhibit.description} filing on {formatDate(highlight.filing.filing_date)}
                    </p>
                ) : (
                    <p className="text-xs font-normal my-1 text-gray-900 dark:text-gray-100">
                        {highlight.transcript.event_type} - {highlight.transcript.event_title} filing on {formatDate(highlight.transcript.event_date)}
                    </p>
                )}
            </div>
            <p className="text-gray-700 dark:text-gray-300 mb-1 p-1 border rounded-xs border-gray-300 dark:border-gray-600 bg-gray-100 dark:bg-gray-800 border-l-0 border-r-0 text-sm">{highlight.text}</p>
            <div className="flex justify-between items-center">
                <p className="text-gray-700 dark:text-gray-400 text-xs">{new Date(highlight.created).toLocaleString([], { dateStyle: "short", timeStyle: "short" })}</p>
                {highlight.documentType === "filing" ? (
                    <a href={`/company/${highlight.company.ticker}/filing/${highlight.filing.filing_type}/${highlight.fid}?hl_start=${highlight.startIndex}&hl_end=${highlight.endIndex}`}
                       className="px-2 py-1 bg-gray-500 dark:bg-gray-600 text-sm text-white rounded hover:bg-green-700 dark:hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-700 dark:focus:ring-green-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
                       target="_blank"
                       rel="noopener noreferrer">
                        Open Filing
                    </a>
                ) : (
                    <a href={`/company/${highlight.company.ticker}/transcript/${highlight.documentID}?hl_start=${highlight.startIndex}&hl_end=${highlight.endIndex}`}
                       className="px-2 py-1 bg-gray-500 dark:bg-gray-600 text-sm text-white rounded hover:bg-green-700 dark:hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-700 dark:focus:ring-green-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
                       target="_blank"
                       rel="noopener noreferrer">
                        Open Transcript
                    </a>
                )}
            </div>
        </div>
    );

    return (
        <div className="bg-gray-200 dark:bg-gray-900 bg-cover bg-center min-h-screen flex flex-col justify-between h-screen">
            <NavBarSmall />
            <div className="bg-gray-200 dark:bg-gray-900 flex-grow flex items-center justify-center p-1 sm:p-2 md:p-4">
                <div className="bg-gray-200 dark:bg-gray-900 min-h-screen flex flex-col">
                    <div className="flex-grow flex items-start justify-center p-1 sm:p-2 md:p-3">
                        <div className="">
                            <div className="rounded-lg w-full max-w-6xl flex">
                                <div className="md:w-1/4 w-1/3 min-h-screen border-r border-gray-400 dark:border-gray-600 p-2 sm:p-3 md:p-4 overflow-y-auto" style={{ maxHeight: 'calc(100vh - 2rem)' }}>
                                    <h2 className="text-lg font-semibold mb-2 sm:mb-3 md:mb-4 text-gray-900 dark:text-gray-100">Companies</h2>
                                    {highlightData?.map((company) => (
                                        <button
                                            key={company.company.cik}
                                            onClick={() => setSelectedCompany(company.company.cik)}
                                            className={`w-full text-left p-1 sm:p-2 rounded ${
                                                selectedCompany === company.company.cik 
                                                ? 'bg-blue-100 dark:bg-blue-900 border border-gray-900 dark:border-gray-100 text-gray-900 dark:text-gray-100' 
                                                : 'border border-gray-200 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-800 text-gray-700 dark:text-gray-300'
                                            }`}
                                        >
                                            {company.company.name}
                                        </button>
                                    ))}
                                </div>

                                {/* Highlights */}
                                <div className="w-3/4 p-2 sm:p-3 md:p-4 overflow-y-auto">
                                    <h2 className="text-lg font-semibold mb-2 sm:mb-3 md:mb-4 text-gray-900 dark:text-gray-100">Highlights</h2>
                                    {highlightData && selectedCompany && (
                                        highlightData.find(company => company.company.cik === selectedCompany)?.highlights.map(renderHighlight)
                                    )}
                                    {(!highlightData || !selectedCompany || highlightData.find(company => company.company.cik === selectedCompany)?.highlights.length === 0) && (
                                        <div className="text-center p-4 sm:p-6 md:p-8 bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700">
                                            <h2 className="text-xl font-light text-gray-700 dark:text-gray-300 mb-2 sm:mb-3">No highlights found</h2>
                                            <p className="text-sm text-gray-500 dark:text-gray-400 mb-4 sm:mb-6">Select a company or add highlights to build your collection.</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Highlights;