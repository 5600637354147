import React, { useState, useEffect, useCallback, useRef } from "react";
import axiosInstance from '../../../../shared/services/api';
import { useAuth } from "../../../auth";
import { PortfolioColumn } from "../../components/column";
import { CompanyColumn } from "../company";
import { API_URL } from "../../../../config";
import { NavBarSmall } from "../../../../shared/components/navigation";
import { FooterSmall } from "../../../layout";
import { EmptyPortfolio } from "../../components/empty";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../../../contexts/ThemeContext";
import { themeColors } from "../../../../theme/colors";

function Portfolio() {
    const [portfolio, setPortfolio] = useState(null);
    const { currentUser } = useAuth();
    const [readyToNavigate, setReadyToNavigate] = useState(false);
    const navigate = useNavigate();
    const [isDataEmpty, setIsDataEmpty] = useState(false);
    const [hasFetched, setHasFetched] = useState(false);
    const fetchAttempted = useRef(false);

    useEffect(() => {
        document.title = "Portfolio | Modeledge";
    }, []);

    useEffect(() => {
        if (readyToNavigate && !currentUser) {
            navigate('/login');
        } else {
            console.log('User logged in');
        }
    }, [readyToNavigate, currentUser, navigate]);

    // Timer setup to change readyToNavigate after 3 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            setReadyToNavigate(true);
        }, 2500);
        return () => clearTimeout(timer);
    }, []);

    const fetchMainPortfolio = useCallback(async () => {
        if (fetchAttempted.current || !currentUser || !currentUser.token || hasFetched) {
            return;
        }

        fetchAttempted.current = true
        try {
                const headers = currentUser
                    ? { Authorization: `Bearer ${currentUser.token}` }
                    : {};
                const res = await axiosInstance.get(`${API_URL}/api/users/portfolio/main`, { headers });
                setPortfolio(res.data);
                setHasFetched(true);
                console.log("calls to backend")

            if (Array.isArray(res.data) && res.data.length === 0) {
                setIsDataEmpty(true);
            } else if (res.data.companies.length === 0) {
                setIsDataEmpty(true);
            } else {
                console.log("Is array: " + Array.isArray(res.data))
                setIsDataEmpty(false);
            }

            } catch (error) {
                //
            } finally {
                //
            }
    }, [currentUser, hasFetched]);

    useEffect(() => {
        fetchMainPortfolio();
    }, [fetchMainPortfolio]);

    useTheme(); // Keep theme context subscription active

    return (
        <div className={`bg-filing-menu-background bg-cover bg-center min-h-screen ${themeColors.background.primary}`}>
            <NavBarSmall />
            <div className={`flex flex-col min-h-screen ${themeColors.background.primary}`}>
                {isDataEmpty ? (
                        <EmptyPortfolio />
                    ) : portfolio && portfolio.companies && (
                    <div className={`w-full ${themeColors.background.primary}`}>
                        <div className="flex flex-col md:flex-row justify-center items-start max-w-7xl mx-auto mt-8">
                            <div className="w-full md:w-3/4 lg:w-3/4 md:px-4 px-2 mb-8">
                                <h3 className={`text-xl font-semibold mb-4 ${themeColors.text.primary}`}>Recent Filings</h3>
                                {portfolio.filings && <PortfolioColumn filings={portfolio.filings} currentUser={currentUser} />}
                            </div>
                            <div className="w-full md:w-1/4 lg:w-1/4 md:px-4 px-1 mb-8">
                                <h3 className={`text-xl font-semibold mb-4 ${themeColors.text.primary}`}>Followed Companies</h3>
                                <CompanyColumn companies={portfolio.companies} />
                            </div>
                        </div>
                    </div>
                    )}
            </div>
            <FooterSmall/>
        </div>
    );
}

export default Portfolio;