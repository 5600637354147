import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Arrow } from "../../../../shared/components/ui";
import FilingMenuExhibitList from "./FilingMenuExhibitList";
import FilingMenuSecondaryData from "./FilingMenuSecondaryData";
import { CompanyButton } from "../../../../features/company/components/buttons";
import { Logo } from "../../../../shared/components/branding";

/**
 * SECFilingMenu Component
 * Displays the navigation menu for SEC filings including company information,
 * filing details, and a list of exhibits.
 */
const SECFilingMenu = ({
    ticker,
    type,
    filingData,
    menuVisible,
    toggleMenu,
}) => {
    if (!filingData?.company || !filingData?.sec_filing || !filingData?.exhibits) {
        return (
            <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-800">
                <div className="text-gray-600 dark:text-gray-300 animate-pulse">
                    Loading filing data...
                </div>
            </div>
        );
    }

    const { company, sec_filing, exhibits } = filingData;

    return (
        <div
            className={`relative transition-transform duration-100 z-5 h-screen
                ${menuVisible ? "" : "translate-x-[-100%+25px]"}`}
        >
            <Arrow onClick={toggleMenu} variant="filing" />
            
            <div className="font-sans bg-gray-100 dark:bg-gray-800 h-full w-80 flex flex-col shadow-lg">
                <header className="flex justify-center py-4 mb-2 border-b border-gray-100 dark:border-gray-700">
                    <h1 className="font-nunito text-gray-900 dark:text-gray-100 text-2xl font-semibold">
                        <Logo />
                    </h1>
                </header>

                <div className="flex-1 overflow-y-auto px-4">
                    <section className="mb-6">
                        <div className="bg-gray-100 dark:bg-gray-800 rounded-lg shadow-sm p-2 border border-gray-100 dark:border-gray-700">
                            <CompanyButton company={company} ticker={ticker} />
                            <FilingMenuSecondaryData sec_filing={sec_filing} />
                        </div>
                    </section>

                    <section className="mb-6">
                        <h3 className="text-sm font-bold text-gray-700 dark:text-gray-300 mb-3 flex items-center">
                            <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                            </svg>
                            Filing
                        </h3>
                        <Link
                            to={`/company/${ticker}/filing/${type}/${sec_filing.AccessionNumber}/exhibit/1`}
                            className="block bg-white dark:bg-gray-700 rounded-lg shadow-sm transition-all duration-200 border border-gray-200 dark:border-gray-600 hover:shadow-md hover:border-blue-300 dark:hover:border-blue-500"
                        >
                            <div className="px-3 py-2">
                                <div className="font-medium text-gray-900 dark:text-white flex items-center">
                                    <span className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 px-2 py-1 rounded text-sm mr-2">
                                        {sec_filing.FilingType}
                                    </span>
                                </div>
                                <div className="text-sm text-gray-600 dark:text-gray-300 mt-1">
                                    {sec_filing.Title}
                                </div>
                            </div>
                        </Link>
                    </section>

                    <section className="mb-4">
                        <h3 className="text-sm font-bold text-gray-700 dark:text-gray-300 mb-3 flex items-center">
                            <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2" />
                            </svg>
                            Exhibits
                        </h3>
                        <div className="space-y-2">
                            <FilingMenuExhibitList
                                exhibits={exhibits}
                                ticker={ticker}
                                type={type}
                                sec_filing={sec_filing}
                            />
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

SECFilingMenu.propTypes = {
    ticker: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    filingData: PropTypes.shape({
        company: PropTypes.object,
        sec_filing: PropTypes.object,
        exhibits: PropTypes.array,
    }),
    menuVisible: PropTypes.bool.isRequired,
    toggleMenu: PropTypes.func.isRequired,
};

export default SECFilingMenu;