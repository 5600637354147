import React, { useState, useEffect, useRef, useMemo } from "react";
import axios from "axios";
import Modal from 'react-modal';
import { API_URL } from "../../../../config";

Modal.setAppElement('#root')

function CompanyDocumentSearchInput({ ticker }) {
    const [searchTerm, setSearchTerm] = useState("");
    const [, setTables] = useState([]); // Only using the setter, not the value
    const [, setIsOpen] = useState(false); // Only using the setter, not the value
    const searchBarRef = useRef(null);

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
        setTables([]);
    }

    const customStyles = useMemo(() => ({
        content: {
            top: '70%',  
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)', 
            overflowY: 'auto',
            maxHeight: '80vh',
            backgroundColor: '#f9fafb',
            color: 'black',
            width: '98%',
            maxWidth: '900px',
            padding: '10px',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
        }
    }), []);

    useEffect(() => {
        if (searchTerm) {
            if (searchTerm && searchBarRef.current) {
                const searchBarPosition = searchBarRef.current.getBoundingClientRect();
                const modalTopPosition = searchBarPosition.bottom + window.scrollY;
                console.log(`${modalTopPosition}px`)
                customStyles.content.top = `${modalTopPosition}px`; 
            }
            axios
                .get(`${API_URL}/api/company/documents/search/${ticker}?q=${searchTerm}`)

                .then((response) => {
                    const tables = response.data;
                    setTables(tables);
                    if (tables != null && tables.length > 0) {
                        openModal();
                    } else {
                        closeModal();
                    }
                })
                .catch((error) => {
                    console.error("Error fetching suggestions:", error);
                    setTables([]);  
                    closeModal();
                });
        } else {
            setTables([]);
            closeModal();
        }
    }, [searchTerm, ticker, customStyles]);



    return (
        <div className="flex items-center bg-gray-100 rounded p-2 h-10 relative w-full max-w-4xl mx-auto">
            <div className="flex items-center bg-gray-100 rounded-full p-2 h-10 relative w-full max-w-4xl mx-auto">
                <input
                    type="text"
                    placeholder="Table search by keyword"
                    className="border-none bg-transparent text-gray-600 text-xs px-3 outline-none w-full rounded-md"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
        </div>
    );
}

export default CompanyDocumentSearchInput;