import { useTheme } from '../../../contexts/ThemeContext';
import { themeColors } from '../../../theme/colors';

function SpacerCard({ text }) {
    const { isDarkMode } = useTheme();
    
    return (
        <div className={`p-1 pl-3 py-2 ${isDarkMode ? 'bg-gray-800' : 'bg-background-cover-theme'} shadow-2xl ${themeColors.text.primary} text-xs font-semibold`}>
            {text}
        </div>
    );
}
export default SpacerCard;