import React from 'react';

/**
 * CheckBullet component for displaying a checkmark bullet point with text
 * 
 * @param {Object} props - Component props
 * @param {ReactNode} props.children - Additional content
 * @param {string} props.text - Text to display next to the bullet
 * @param {string} props.className - Additional CSS classes for container
 * @param {string} props.iconClassName - Additional CSS classes for icon
 * @returns {JSX.Element} CheckBullet component
 */
const CheckBullet = ({ children, text, className = '', iconClassName = '', ...props }) => {
    return (
        <div className={`flex flex-row ${className}`} {...props}>
            <svg 
                className={`icon fill-current text-neutral-800 dark:text-neutral-200 ${iconClassName}`} 
                width="22" 
                height="22" 
                viewBox="0 0 24 24"
            >
                <path d="M10.6 16.6L17.65 9.55L16.25 8.15L10.6 13.8L7.75 10.95L6.35 12.35L10.6 16.6ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z"></path>
            </svg>
            <div className="mx-2 text-gray-900 dark:text-white">{text}</div>
            {children}
        </div>
    );
};

export default CheckBullet;