import React, { useState, useRef, useEffect } from 'react';
import { useTheme } from '../../../../contexts/ThemeContext';
import useSSE from '../../../sse/hooks/useSSE';
import { API_URL } from "../../../../config";
import { LoginRequiredModal } from "../../../../features/auth/components";
import { useSubscriptionModal } from "../../../../features/subscription/components/modal/useSubscriptionModal";
import { Button } from "../../../../shared/components/ui";
import { SubscriptionModal } from "../../../../features/subscription/components/modal";
import { CompanyArtifact, CompanyArtifactList } from '../artifact';
import { QueryOptions2 } from '../options';

const CompanyQuery = ({ subscriptionTier, companyID, currentUser, refreshToken }) => {
    const { isDarkMode } = useTheme();
    const [isStreaming, setIsStreaming] = useState(false);
    const [streamUrl, setStreamUrl] = useState('');
    const [query, setQuery] = useState('');
    const [queryRequested, setQueryRequested] = useState('');
    const [dataSet, setDataSet] = useState('current_data');
    const { data, error, streamId, isComplete } = useSSE(streamUrl);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [displayData, setDisplayData] = useState('');
    const [localError, setLocalError] = useState(null);
    const { showSubscriptionModal, handleSubscriptionModal, handleSubscriptionModalClose } = useSubscriptionModal();
    const [showFeedback, setShowFeedback] = useState(false);
    const inputRef = useRef(null);
    const responseRef = useRef(null);
    const [currentArtifactId, setCurrentArtifactId] = useState(null);

    const handleDeleteArtifact = (deletedStreamId) => {
        console.log('Artifact deleted:', deletedStreamId);
        if (currentArtifactId === deletedStreamId) {
            setIsStreaming(false);
            setDisplayData('');
            setShowFeedback(false);
            setQueryRequested('');
            setQuery('');
            setCurrentArtifactId(null);
        }
        // Trigger a refresh of the CompanyArtifactsList
        if (companyArtifactsListRef.current) {
            companyArtifactsListRef.current.refreshArtifacts();
        }
    };

    const handleAIQuery = () => {
        if (!currentUser?.token) {
            setIsLoginModalOpen(true);
            return;
        }
        setDisplayData('');
        setLocalError(null);
        setIsStreaming(true);
        setShowFeedback(false);
        setQueryRequested(query);

        let dataId = 'current_data';
        if (dataSet === 'transcript_data') {
            dataId = 'tc';
        } else if (dataSet === 'transcripts_full_text') {
            dataId = 'tft';
        } else if (dataSet === 'current_data') {
            dataId = 'cd';
        } else if (dataSet === 'most_recent_transcript') {
            dataId = 'mrt';
        }

        setStreamUrl(`${API_URL}/api/company/query/llm/${companyID}?q=${query}&auth=${currentUser.token}&d=${dataId}`);
    };

    const companyArtifactsListRef = useRef(null);

    useEffect(() => {
        if (data && streamId) {
            setDisplayData(data);
            setIsStreaming(false);
            setCurrentArtifactId(streamId);
        }
    }, [data, streamId]);

    useEffect(() => {
        if (streamId) {
            console.log("stream_id: " + streamId)
        }
    }, [streamId]);

    useEffect(() => {
        if (isComplete && !isStreaming) {
            setShowFeedback(true)
        }
    }, [isComplete, isStreaming])

    // New effect for scrolling the page to the bottom
    useEffect(() => {
        if (responseRef.current) {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth'
            });
        }
    }, [displayData]);

    useEffect(() => {
        if (localError) {
            setIsStreaming(false);
            switch(localError.type) {
                case 'forbidden':
                    handleSubscriptionModal();
                    break;
                case 'unauthorized':
                    setIsLoginModalOpen(true);
                    break;
                case 'connection':
                case 'unknown':
                default:
                    setDisplayData('');
                    break;
            }
        }
    }, [localError, handleSubscriptionModal]);


    useEffect(() => {
        if (error) {
            console.log('Component received error:', error);
            setLocalError(error);
            setIsStreaming(false);  // Enable the button if there's an error

            if (error.message === "Unauthorized") {
                console.log("User: " + currentUser.token);
                refreshToken();
            }
        }
    }, [error, currentUser, refreshToken]);


    const getCurrentFormattedDate = () => {
        const now = new Date();
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        const year = now.getFullYear();
        const month = months[now.getMonth()];
        const day = now.getDate();
        const hour = now.getHours();
        const minute = now.getMinutes().toString().padStart(2, '0');
        const period = hour >= 12 ? 'PM' : 'AM';
        const formattedHour = hour % 12 || 12;

        return `${month} ${day}, ${year}, ${formattedHour}:${minute} ${period}`;
    };

    return (
        <div className={`w-full ${isDarkMode ? 'bg-gray-900' : 'bg-gradient-to-b from-gray-100 to-white'}`}>
            <div className={`max-w-4xl mx-auto min-h-screen rounded-lg shadow-lg px-6 pt-4 pb-2 ${isDarkMode ? 'bg-gray-900' : 'bg-gradient-to-b from-gray-100 to-white'}`}>
                <h2 className={`text-xl font-bold mb-2 ${isDarkMode ? 'text-gray-100' : 'text-gray-900'}`}>Qualitative data AI query</h2>
                <div className="space-y-6">
                    <div className="pt-2">
                        <QueryOptions2 setDataSet={setDataSet} isDarkMode={isDarkMode} />
                    </div>

                    <div className="relative rounded-lg focus:ring-0">
                        <textarea
                            ref={inputRef}
                            placeholder="Query recent company documents..."
                            value={query}
                            onChange={(e) => {
                                setQuery(e.target.value);
                                e.target.style.height = 'auto';
                                e.target.style.height = `${e.target.scrollHeight}px`;
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    if (!isStreaming && query.trim()) handleAIQuery();
                                }
                            }}
                            className="w-full p-4 min-h-[120px] bg-white rounded-lg border border-gray-200 dark:border-neutral-700 bg-transparent dark:bg-gray-700 resize-none focus:ring-1 focus:ring-green-700 ring-0"
                        />
                        <div className="absolute bottom-4 right-6">
                            <Button
                                onClick={handleAIQuery}
                                disabled={isStreaming || !query.trim()}
                                className={`flex items-center px-4 py-2 text-white rounded-md shadow-lg transition-colors duration-300 ${
                                    isStreaming || !query.trim() 
                                    ? 'bg-gray-400 dark:bg-gray-600 cursor-not-allowed' 
                                    : 'bg-blue-600 dark:bg-blue-700 hover:bg-blue-700 dark:hover:bg-blue-800'
                                }`}
                            >
                                {isStreaming ? (
                                    <div className="flex items-center">
                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Processing...
                                    </div>
                                ) : (
                                    <>
                                        <span className="ml-2">Query</span>
                                    </>
                                )}
                            </Button>
                        </div>
                    </div>
                </div>

                {(displayData || isStreaming) ? (
                    <div className="bg-white dark:bg-gray-900 rounded-lg shadow-lg mt-6">
                        <div className="p-6">
                            <CompanyArtifact
                                currentUser={currentUser}
                                queryRequested={queryRequested}
                                streamId={currentArtifactId}
                                responseRef={responseRef}
                                isStreaming={isStreaming}
                                displayData={displayData}
                                showFeedback={showFeedback}
                                createdDate={getCurrentFormattedDate()}
                                onDelete={handleDeleteArtifact}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="bg-white dark:bg-gray-900 rounded-lg shadow-lg mt-2">
                        <div className="p-6">
                            <CompanyArtifactList
                                ref={companyArtifactsListRef}
                                currentUser={currentUser}
                                companyID={companyID}
                                onDelete={handleDeleteArtifact}
                            />
                        </div>
                    </div>
                )}

                {localError && (
                    <div className="mt-4 p-4 bg-red-50 dark:bg-red-900/50 border border-red-200 dark:border-red-800 text-red-700 dark:text-red-200 rounded-lg">
                        <div className="flex items-center space-x-2">
                            <span>⚠️</span>
                            <div>
                                <p className="font-medium">Error Type: {localError.type}</p>
                                <p className="text-sm mt-1">Error Message: {localError.message}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <LoginRequiredModal isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)} />
            <SubscriptionModal
                isOpen={showSubscriptionModal}
                onClose={handleSubscriptionModalClose}
                subscriptionRequired="Basic"
                pageMessage="AI Language Model queries available under our Basic subscription."
            />
        </div>
    );
};

export default CompanyQuery;