export function SubscriptionModal({ isOpen, onClose, subscriptionRequired, pageMessage }) {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black dark:bg-gray-900 bg-opacity-50 dark:bg-opacity-70 overflow-y-auto h-full w-full z-50 flex items-center justify-center min-w-fit">
            <div className="relative mx-auto p-8 border border-gray-300 dark:border-gray-600 md:w-1/3 min-w-fit shadow-2xl rounded-lg bg-white dark:bg-gray-800">
                <div className="text-center">
                    <h3 className="text-2xl font-bold text-gray-900 dark:text-gray-100">{subscriptionRequired} Subscription Required</h3>
                    <div className="mt-4 px-6 py-4">
                        <p className="text-sm text-gray-600 dark:text-gray-300 mb-4">
                            {pageMessage}
                        </p>
                        <div className="border-t border-gray-200 dark:border-gray-700 pt-4">
                            <ul className="list-disc list-inside pl-5 text-left text-gray-700 dark:text-gray-300">
                                <li className="mb-2">Transcripts</li>
                                <li className={"mb-2"}>AI Language Model Querying</li>
                                <li className="mb-2">Table look up</li>
                                <li className="mb-2">Full text search</li>
                                <li className="mb-2">And grandfathered into all future features</li>
                            </ul>
                        </div>
                    </div>
                    <div className="mt-6">
                        <button
                            id="ok-btn"
                            className="w-full py-3 bg-blue-600 dark:bg-blue-500 text-white text-base font-semibold rounded-md shadow-sm hover:bg-blue-700 dark:hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-200 ease-in-out"
                            onClick={onClose}
                        >
                            Subscription Options
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubscriptionModal;