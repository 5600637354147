import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../../shared/services/api';
import TableSearchWithModal from './TableSearchWithModal';
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../../auth/firebase/AuthContext";
import { CompanyButton } from '../../../../features/company/components/buttons';
import { API_URL } from "../../../../config";
import { Logo } from '../../../../shared/components/branding';
import { ArrowTableSearch } from "../company";
import { FilingCardCompanySearchV2, FilingCardCompanySearch } from "../../../../features/filings/components/cards";
import '../../styles/TableSearch.css';

/**
 * Filing list component for displaying a list of filings with tables
 * @param {Object} props - Component props
 * @param {Array} props.filingData - Array of filings with tables
 * @param {Function} props.handleFilingClick - Function to handle clicking on a filing
 * @param {string} [props.variant='v2'] - Variant of the filing list to display ('default', 'v2')
 */
const FilingList = ({ filingData, handleFilingClick, variant = 'v2' }) => {
    
    if (variant === 'filings') {
        return (
            <div>
                {filingData && filingData.map((filing) => (
                    filing.tables.map((table, index) => (
                        <div
                            key={table.id}
                            className="flex flex-col border border-gray-300 rounded-xs mx-2 my-1 px-0 cursor-pointer text-black w-9/10 max-w-4xl bg-gray-50 dark:bg-gray-800 dark:text-gray-100"
                            onClick={() => handleFilingClick(table)}
                        >
                            <FilingCardCompanySearch filing={table.filing} />
                        </div>
                    ))
                ))}
            </div>
        );
    }
    
    // Default v2 variant
    return (
        <div className="bg-white dark:bg-gray-800 shadow-sm rounded-md overflow-hidden">
            {filingData.map((exhibitWithTable, index) => (
                <div
                    key={`${exhibitWithTable.exhibit?.eid || exhibitWithTable.Exhibit?.id}-${index}`}
                    className="flex flex-col bg-gray-600 hover:bg-gray-500 px-0 cursor-pointer rounded-sm"
                    onClick={() => handleFilingClick(exhibitWithTable)}
                >
                    <FilingCardCompanySearchV2 
                        filing={exhibitWithTable.exhibit?.filing || exhibitWithTable.Exhibit?.edges?.sec_filing} 
                        allSmall={true}
                    />
                </div>
            ))}
        </div>
    );
};

/**
 * TableSearchPage component for displaying tables from SEC filings
 * @param {Object} props - Component props 
 * @param {string} [props.variant='v2'] - Variant of the table search page ('default',  'v2', 'filings')
 */
function TableSearchPage({ variant = 'v2' }) {
    const { ticker, table_id } = useParams();
    const [exhibitWithTables, setExhibitWithTables] = useState([]);
    const [filings, setFilings] = useState(null);
    const [selectedTable, setSelectedTable] = useState(null);
    const [selectedExhibitId, setSelectedExhibitId] = useState(null);
    const [company, setCompany] = useState(null);
    const { currentUser } = useAuth();
    const [menuVisible, setMenuVisible] = useState(window.innerWidth > 768);
    const [tableHTMLs, setTableHTMLs] = useState({});
    const navigate = useNavigate();

    // Get selected exhibit based on variant
    const selectedExhibit = exhibitWithTables.find(exhibit => exhibit.exhibit?.eid === selectedExhibitId);

    // Set document title
    useEffect(() => {
        if (company) {
            document.title = company.name + " Tables | Modeledge";
        }
    }, [company]);

    // Fetch table data
    useEffect(() => {
        const headers = currentUser
            ? { Authorization: `Bearer ${currentUser.token}` }
            : {};

        axiosInstance
            .get(`${API_URL}/api/tables/series/filings?id=${table_id}`, { headers })
            .then((response) => {
                const responseData = response.data;
                
                if (variant === 'filings') {
                    if (responseData && responseData.filings) {
                        setFilings(responseData.filings);
                        setCompany(responseData.company);
                        // Find and set the selected table
                        let foundTable;
                        responseData.filings.forEach(filing => {
                            const table = filing.tables.find(table => table.id === table_id);
                            if (table) foundTable = table;
                        });
                        if (foundTable) setSelectedTable(foundTable);
                    } else {
                        console.error("Unexpected response structure:", responseData);
                    }
                    return;
                }
                
                if (responseData && responseData.results && Array.isArray(responseData.results)) {
                    const exhibits = responseData.results.map(result => result.ExhibitWithTables);
                    setExhibitWithTables(exhibits);
                    setCompany(responseData.company);

                    let found = false;
                    for (const exhibitWithTable of exhibits) {
                        const tables = exhibitWithTable.tables;
                        
                        if (!tables) continue;
                        
                        for (const table of tables) {
                            if (table.id === table_id) {
                                setSelectedTable(table);
                                setSelectedExhibitId(
                                    exhibitWithTable.exhibit.eid
                                );
                                found = true;
                                break;
                            }
                        }
                        if (found) break;
                    }

                    if (!found) {
                        console.error("Table not found for given ID:", table_id);
                        setSelectedTable(null);
                        setSelectedExhibitId(null);
                    }
                } else {
                    console.error("Unexpected response structure:", responseData);
                    setSelectedTable(null);
                    setSelectedExhibitId(null);
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setSelectedTable(null);
                setSelectedExhibitId(null);
            });
    }, [table_id, currentUser, variant]);

    // Fetch HTML for tables when needed (for v2 variant)
    useEffect(() => {
        if (variant !== 'v2' || !company || !selectedExhibit) {
            return;
        }
        
        // Reset tableHTMLs when a new exhibit is selected
        setTableHTMLs({});

        const fetchTableHTML = async (tableId) => {
            const headers = currentUser
                ? { Authorization: `Bearer ${currentUser.token}` }
                : {};

            try {
                const response = await axiosInstance.get(
                    `${API_URL}/api/tables/html/${company.cik}/${tableId}`,
                    { headers }
                );
                return response.data;
            } catch (error) {
                console.error(`Error fetching HTML for table ${tableId}:`, error);
                return '';
            }
        };

        const updateTableHTMLs = async () => {
            const newTableHTMLs = {};
            const tables = selectedExhibit.tables || [];
            for (const table of tables) {
                newTableHTMLs[table.id] = await fetchTableHTML(table.id);
            }
            setTableHTMLs(newTableHTMLs);
        };

        updateTableHTMLs();
    }, [selectedExhibitId, currentUser, company, selectedExhibit, variant]);

    // Handle opening filing in new tab
    const openFiling = () => {
        if (variant === 'filings') {
            window.open(`/company/${ticker}/filing/${selectedTable.filing.filingType}/${selectedTable.filing.accessionNumber}#table-${selectedTable.htmlTableIndex}`, "_blank");
            return;
        }
        
        if (!selectedExhibit || !selectedTable) {
            console.error("Selected exhibit or table is null");
            return;
        }

        let filingUrl, tableIndex;
        

        filingUrl = `/company/${ticker}/filing/${selectedExhibit.exhibit.type}/${selectedExhibit.exhibit.url.split('/')[1]}`;
        tableIndex = selectedTable.html_table_index ? `#table-${selectedTable.html_table_index}` : '';


        window.open(`${filingUrl}${tableIndex}`, "_blank");
    };

    // Toggle menu visibility
    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    // Format filing date
    const formatFilingDate = (dateString) => {
        try {
            const date = new Date(dateString);
            const year = date.getFullYear().toString().substr(-2);
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            const day = ("0" + date.getDate()).slice(-2);
            return `${month}/${day}/${year}`;
        } catch (e) {
            console.error("Error formatting date:", e);
            return "N/A";
        }
    };

    // Handle clicking on a filing
    const handleExhibitSelect = (item) => {
        if (variant === 'filings') {
            setSelectedTable(item);
            navigate(`/tables/${ticker}/${item.id}`);
            return;
        }
        

        setSelectedExhibitId(item.exhibit.eid);
        setSelectedTable(item.tables[0]);

    };

    // Render the filing content based on variant
    const renderFileContent = () => {
        if (variant === 'filings') {
            return selectedTable?.html ? (
                <div>
                    <h4 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
                        From {selectedTable.filing.filingType} ({formatFilingDate(selectedTable.filing.filingDate)})
                    </h4>
                    <div 
                        className="border border-gray-400 dark:border-gray-600 rounded p-3 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100" 
                        dangerouslySetInnerHTML={{ __html: selectedTable.html }} 
                    />
                    <button 
                        onClick={openFiling} 
                        className="bg-blue-600 dark:bg-blue-700 hover:bg-blue-700 dark:hover:bg-blue-800 text-white font-medium py-2 px-4 mt-2 rounded"
                    >
                        Open Filing
                    </button>
                </div>
            ) : <p>Select a table to view details.</p>;
        } else {
            // v2 variant (default)
            return selectedExhibit && selectedExhibit.tables.map((table, index) => (
                tableHTMLs[table.id] ?
                    <div key={`${selectedExhibitId}-${table.id}`} className="mb-4">
                        <div>
                            <h4 className="text-xl font-bold text-gray-900 dark:text-gray-100">
                                From {selectedExhibit.exhibit.type} ({formatFilingDate(selectedExhibit.exhibit.filing_date)})
                            </h4>
                            <p className="text-sm py-1 px-1">...{table.text}</p>
                        </div>
                        <div
                            key={tableHTMLs[table.id] ? `${table.id}-${tableHTMLs[table.id].length}` : `${table.id}-initial`}
                            className="border border-gray-300 dark:border-gray-600 rounded-sm p-3 bg-white dark:bg-gray-800"
                        >
                            <div
                                className="border border-gray-300 dark:border-gray-600 p-1 rounded-sm bg-white text-gray-900 break-words"
                                dangerouslySetInnerHTML={{ __html: tableHTMLs[table.id] }}
                            />
                        </div>
                        <button
                            onClick={openFiling}
                            className="bg-blue-600 dark:bg-blue-700 hover:bg-blue-700 dark:hover:bg-blue-800 text-white font-medium py-2 px-4 mt-2 rounded-md shadow-sm hover:shadow transition-all duration-200"
                        >
                            Open Filing
                        </button>
                    </div> : ''
            ));
        }
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-100 dark:bg-gray-900 pl-2">
            <div className="flex flex-row flex-grow">
                <div className={`transition-all duration-300 ease-in-out ${menuVisible ? 'w-80' : 'w-4'} overflow-y-auto overflow-x-hidden h-screen `}>
                    {variant !== 'filings' && <ArrowTableSearch onClick={toggleMenu} />}
                    
                    <div className="flex justify-center items-center text-gray-900 dark:text-gray-100 my-4">
                        <Logo menuOpened={menuVisible} className="mr-6 ml-2" />
                    </div>
                    
                    <div className={"rounded-sm mb-2 px-4"}>
                        <CompanyButton company={company} ticker={ticker}/>
                    </div>
                    
                    <div className="p-4">
                        <TableSearchWithModal ticker={ticker} />
                        
                        <div className={"my-2"}>
                            <h2 className="text-gray-900 dark:text-gray-100 font-semibold">Results</h2>
                        </div>
                        
                        {variant === 'filings' 
                            ? <FilingList handleFilingClick={handleExhibitSelect} filingData={filings} variant="filings" />
                            : <FilingList handleFilingClick={handleExhibitSelect} filingData={exhibitWithTables} variant={variant} />
                        }
                    </div>
                </div>

                <div className="flex-1 min-h-screen bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 p-5 md:pr-10 overflow-x-auto">
                    {renderFileContent()}
                </div>
            </div>
        </div>
    );
}

export default TableSearchPage;