import React from 'react';
import { Link } from 'react-router-dom';

/**
 * CompanyButton component for displaying a company button
 * Consolidated version that supports dark mode and multiple style variants
 * 
 * @param {Object} props - Component props
 * @param {Object} props.company - Company data object
 * @param {string} props.ticker - Company ticker symbol
 * @param {string} props.className - Additional CSS classes
 * @param {string} props.variant - Button style variant: 'default', 'v2', 'v3', or 'v4' (compact)
 * @returns {JSX.Element} CompanyButton component
 */
const CompanyButton = ({ company, ticker, className = '', variant = 'default' }) => {
    const displayName = company?.name || 'Company Overview';
    const displayTicker = ticker || '';

    // V2 variant (with left arrow)
    if (variant === 'v2') {
        return (
            <div className={`rounded-sm mx-2 px-1 ${className}`}>
                <Link
                    to={`/company/${ticker}`}
                    className="block transition-colors duration-200"
                >
                    <div className="flex items-center justify-between px-4 py-2 text-sm bg-gray-700 dark:bg-gray-800 hover:bg-gray-600 dark:hover:bg-gray-700 text-gray-100 rounded-md shadow-sm">
                        <div className="flex items-center">
                            <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                            </svg>
                            <span className="font-medium">{displayName}</span>
                        </div>
                        <span className="bg-gray-600 dark:bg-gray-700 px-2 py-1 rounded text-xs font-semibold">{displayTicker}</span>
                    </div>
                </Link>
            </div>
        );
    }

    return (
        <Link
            to={`/company/${ticker}`}
            className={`block transition-all duration-200 ${className}`}
        >
            <div className="flex items-center px-3 py-2 bg-white dark:bg-gray-700 rounded border border-gray-200 dark:border-gray-600 
                hover:border-blue-300 dark:hover:border-blue-500 hover:shadow-sm">
                <svg className="w-3 h-3 mr-2 opacity-60 text-gray-600 dark:text-gray-400" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 12H5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 19L5 12L12 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <div className="flex-1">
                    <div className="font-medium text-gray-900 dark:text-white">
                        {displayName}
                    </div>
                    {displayTicker && (
                        <div className="text-sm text-gray-500 dark:text-gray-400">
                            {displayTicker}
                        </div>
                    )}
                </div>
            </div>
        </Link>
    );
};

export default CompanyButton;