import React from "react";
import ModeledgeName from "./Modeledge";

const LogoBoast = () => {
    return (
        <a href="/" className="flex items-center">
            <img src="/images/logo_modeledge.png" alt="Modeledge logo" className="w-10 h-10" />
            <ModeledgeName />
            <span className="text-sm ml-2"></span>
        </a>
    );
};

export default LogoBoast;