// Company.js
import { API_URL } from '../../../config.js';
import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../shared/services/api';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from "../../auth/firebase/AuthContext";
import { arrayToStringWithCommasAndLengthLimitIntelligent } from "../../../shared/utils/values";

// Will be updated as we move more components to the feature structure
import { NavBarSmall } from "../../../shared/components/navigation";
import { FooterSmall } from "../../layout";
import { FilingCategory } from "../../../features/filings/components/categories";
import { SimilarCompanies } from "./similar";
import { CompanySearchBars } from "./search";
import { CompanyDescription } from "../../../features/ai/components/artifact";
import { InsiderTransactionsGroup } from "../../../features/insiders/components/transactions";
import { TranscriptPageV2 as Transcripts } from "../../../features/transcripts/components/list";
import { CompanyQueryInputV1 } from "../../../features/ai/components/query";

const BetaIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 14" width="30" height="14" className="ml-1 inline-block">
        <rect x="0" y="0" width="30" height="14" rx="7" fill="currentColor" opacity="0.2"/>
        <text x="15" y="10" fontFamily="Arial, sans-serif" fontSize="8" fontWeight="bold" fill="currentColor" textAnchor="middle">BETA</text>
    </svg>
);

const TabComponent = ({ activeTab, setActiveTab }) => {
    const tabs = ['Filings', 'Transcripts', 'Insider', "LLM Query"];

    return (
        <div className="flex border-b border-gray-200 dark:border-gray-700">
            {tabs.map((tab) => (
                <button
                    key={tab}
                    className={`py-1 px-4 font-medium flex items-center ${
                        activeTab === tab
                            ? 'border-b-2 border-neutral-600 dark:border-gray-400 text-neutral-900 dark:text-white bg-company-data-background dark:bg-gray-900'
                            : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300'
                    }`}
                    onClick={() => setActiveTab(tab)}
                >
                    {tab}
                    {tab === "LLM Query" && <BetaIcon />}
                </button>
            ))}
        </div>
    );
};

function Company() {
    const [activeTab, setActiveTab] = useState('Filings');
    const [companyData, setCompanyData] = useState(null);
    const [similarCompanies, setSimilarCompanies] = useState(null);
    const { ticker } = useParams();
    const [filingsData, setFilingsData] = useState(null);
    const [isFollowing, setIsFollowing] = useState(false);
    const { currentUser, refreshToken } = useAuth();
    const navigate = useNavigate();
    const [subscriptionTier, setSubscriptionTier] = useState("free")

    const renderTabContent = () => {
        switch (activeTab) {
            case 'Filings':
                return  <div className="grid grid-cols-1 md:grid-cols-2 border dark:border-gray-700 md:p-3 lg:p-3 sm:p-0 bg-company-data-background dark:bg-gray-800">
                    {renderFilingCategories()}
                </div>
            case 'Transcripts':
                return <div className={"w-full md:p-3 lg:p-3 sm:p-0 bg-company-data-background dark:bg-gray-800"}>
                        <Transcripts ticker={ticker} currentUser={currentUser} />
                        </div>;
            case 'Insider':
                return <div className={"w-full md:p-3 lg:p-3 sm:p-0 bg-company-data-background dark:bg-gray-800"}>
                    <div className="bg-gray-200 dark:bg-gray-700">
                        <h2 className="text-lg font-bold lg:px-4 pt-1 px-2 dark:text-white">Insider Transactions - Grouped by filing</h2>
                        <InsiderTransactionsGroup ticker={ticker} currentUser={currentUser} />
                    </div>
                </div>;
            case 'LLM Query':
                return <div className={"w-full md:px-3 md:p-3 lg:p-3 sm:p-0 bg-company-data-background dark:bg-gray-800"}>

                        <CompanyQueryInputV1 subscriptionTier={subscriptionTier} companyID={companyData.cik} currentUser={currentUser} refreshToken={refreshToken} />

                </div>;
            default:
                return null;
        }
    };

    useEffect(() => {
        if (companyData) {
            document.title = companyData.name + " | Modeledge";
        }
    }, [companyData]);

    const renderFilingCategories = () => {
        if (filingsData && typeof filingsData === 'object' && Object.keys(filingsData).length > 0) {
            return (
                <>
                    <FilingCategory key={`News_${ticker}_${filingsData['News']?.length}`} title="News" filings={filingsData['News'] || []} ticker={ticker} currentUser={currentUser} />
                    <FilingCategory key={`Financial_${ticker}_${filingsData['Financial']?.length}`} title="Financial" filings={filingsData['Financial'] || []} ticker={ticker} currentUser={currentUser} />
                    <FilingCategory key={`Prospectus_${ticker}_${filingsData['Prospectus']?.length}`} title="Prospectus" filings={filingsData['Prospectus'] || []} ticker={ticker} currentUser={currentUser}/>
                    <FilingCategory key={`Proxies_${ticker}_${filingsData['Proxies']?.length}`} title="Proxies" filings={filingsData['Proxies'] || []} ticker={ticker} currentUser={currentUser} />
                    <FilingCategory key={`Ownership_${ticker}_${filingsData['Ownership']?.length}`} title="Ownership" filings={filingsData['Ownership'] || []} ticker={ticker} currentUser={currentUser} />
                    <FilingCategory key={`SEC_Correspondence_${ticker}_${filingsData['SEC Correspondence']?.length}`} title="SEC Correspondence" filings={filingsData['SEC Correspondence'] || []} ticker={ticker} currentUser={currentUser}/>
                </>
            );
        } else {
            return (
                <>
                    <>
                        <div className="bg-white dark:bg-gray-800 p-4 rounded shadow dark:shadow-gray-900">
                            <h2 className="text-lg font-bold mb-2 dark:text-white">News</h2>
                        </div>
                        <div className="bg-white dark:bg-gray-800 p-4 rounded shadow dark:shadow-gray-900">
                            <h2 className="text-lg font-bold mb-2 dark:text-white">Financial</h2>
                        </div>
                        <div className="bg-white dark:bg-gray-800 p-4 rounded shadow dark:shadow-gray-900">
                            <h2 className="text-lg font-bold mb-2 dark:text-white">Prospectus</h2>
                        </div>
                        <div className="bg-white dark:bg-gray-800 p-4 rounded shadow dark:shadow-gray-900">
                            <h2 className="text-lg font-bold mb-2 dark:text-white">Proxies</h2>
                        </div>
                        <div className="bg-white dark:bg-gray-800 p-4 rounded shadow dark:shadow-gray-900">
                            <h2 className="text-lg font-bold mb-2 dark:text-white">Ownership</h2>
                        </div>
                        <div className="bg-white dark:bg-gray-800 p-4 rounded shadow dark:shadow-gray-900">
                            <h2 className="text-lg font-bold mb-2 dark:text-white">SEC Letters</h2>
                        </div>
                    </>
                </>

        );
            }
    };

    const handleFollowClick = () => {
        if (currentUser && !isFollowing) {
            const headers = currentUser
                ? {Authorization: `Bearer ${currentUser.token}`}
                : {};
            const url = `${API_URL}/api/users/portfolio/add/main/${ticker}`;
            axiosInstance.post(url, {}, {headers: headers})  // Modified line
                .then(response => {
                    setIsFollowing(response.data.isFollowing);
                })
                .catch(error => {
                    console.error('Error updating follow status:', error);
                });
        } else if (currentUser && isFollowing) {
            const headers = currentUser
                ? {Authorization: `Bearer ${currentUser.token}`}
                : {};
            const url = `${API_URL}/api/users/portfolio/remove/main/${ticker}`;
            axiosInstance.post(url, {}, {headers: headers})  // Modified line
                .then(response => {
                    setIsFollowing(response.data.isFollowing);
                })
                .catch(error => {
                    console.error('Error updating follow status:', error);
                });
        } else {
            navigate('/login')
        }
    };

    useEffect(() => {
        const headers = currentUser
            ? { Authorization: `Bearer ${currentUser.token}` }
            : {};
        axiosInstance.get(`${API_URL}/api/company/simple/${ticker}`, { headers })
            .then(response => {
                setIsFollowing(response.data.isFollowing)
                setCompanyData(response.data.company);
                setSimilarCompanies(response.data.similar);
                setSubscriptionTier(response.data.subscriptionTier);
            })
            .catch(error => {
                console.error('Error fetching company data:', error);
            });

        axiosInstance.get(`${API_URL}/api/company/filings/${ticker}`, { headers })
            .then(response => {
                setFilingsData(response.data.filings);
            })
            .catch(error => {
                console.error('Error fetching filings data:', error);
            });
    }, [ticker, currentUser, subscriptionTier]);

    useEffect(() => {
        //
    }, [isFollowing]);

    if (!companyData) {
        return (
            <>
                <div className="bg-filing-menu-background dark:bg-gray-900 bg-center min-h-screen">
                    <NavBarSmall />
                    <div className="bg-filing-menu-background dark:bg-gray-900 bg-center h-screen">
                    </div>
                </div>
            </>)
    }

    return (
        <div className="bg-filing-menu-background dark:bg-gray-900 bg-cover bg-center min-h-screen">
            <NavBarSmall />
            <div className="lg:w-4/5 md:w-4/5 mx-auto">
                <div className="md:px-4 lg:px-4 p-1 sm:p-1">
                    <div className="container mx-auto pt-4 pb-2 lg:mt-2 ms:mt-1 px-1 md:px-0 dark:text-gray-100">
                        <div>
                            {
                                companyData.tickers ?
                            <h1 className="text-3xl text-filing-menu-text-color dark:text-white font-bold inline">{companyData.name} ({arrayToStringWithCommasAndLengthLimitIntelligent(companyData.tickers)})</h1>
                                    :
                                    <h1 className="text-3xl text-filing-menu-text-color dark:text-white font-bold inline">{companyData.name}</h1>

                            }
                            <button
                                className={`ml-4 p-1 rounded text-xs font-semibold ${isFollowing ? 'bg-company-button dark:bg-blue-600 text-white hover:bg-blue-700' : 'bg-gray-500 dark:bg-gray-600 text-white hover:bg-gray-600 dark:hover:bg-gray-700'} transition-colors duration-150`}
                                onClick={handleFollowClick}
                            >
                                {isFollowing ? 'Unfollow' : 'Follow'}
                            </button>
                          </div>

                            <p className="text-sm text-filing-menu-text-light-color dark:text-gray-400">{companyData.businessCity}, {companyData.businessState}</p>

                    </div>
                    <CompanyDescription ticker={ticker}/>
                    <SimilarCompanies ticker={ticker} similar={similarCompanies} />
                    <CompanySearchBars ticker={ticker} />
                </div>
                <div className="bg-cover bg-center min-h-screen">
                    <div className="container mx-auto md:p-2 lg:p-2 lg:pt-1 sm:p-1">
                        <TabComponent activeTab={activeTab} setActiveTab={setActiveTab} />
                        <div className="w-full">
                            {renderTabContent()}
                        </div>

                    </div>
                </div>

            </div>
            {activeTab !== 'LLM Query' && <FooterSmall />}
        </div>
    )
}

export default Company;