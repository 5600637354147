import React from 'react';

const LandingBackground = ({ children, className }) => {
    return (
        <div
            className={`min-h-screen bg-center bg-gray-950 ${className}`}
            style={{
                backgroundImage: "url('/hero-background-2.jpg')",
                backgroundSize: 'cover',
            }}
        >
            {children}
        </div>
    );
};

export default LandingBackground;