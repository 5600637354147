import React, { useState, useEffect, useRef, useCallback } from 'react';
import "./styles/SECFilingIFrame.css";
import {API_URL} from "../../../../config";
import {useNavigate} from "react-router-dom";
import { useTheme } from "../../../../contexts/ThemeContext";

const SECFilingIFrame = ({
                             filingDoc,
                             cik,
                             fid,
                             eid,
                             filingData,
                             exhibitNum,
                             menuVisible,
                             style,
                             highlightSentence,
                             highlightIDs,
                             currentUser }) => {
    const proxyUrl = `/api/proxy/`;
    const [iframeSrc, setIframeSrc] = useState(filingDoc);
    const iframeRef = useRef();
    const [iframeReady, setIframeReady] = useState(false);
    const navigate = useNavigate();
    const { isDarkMode } = useTheme();

    const sendMessageToIframe = useCallback((message, retryCount = 0) => {
        const iframe = iframeRef.current;
        if (iframe && iframeReady) {
            iframe.contentWindow.postMessage(message, '*');
        } else if (retryCount < 3) {
            setTimeout(() => sendMessageToIframe(message, retryCount + 1), 1000);
        } else {
            //console.warn('Failed to send message after 3 attempts:', message);
        }
    }, [iframeReady]);

    const sendHighlightSentenceMessage = useCallback((nodeID, sentence) => {
        const message = {
            type: 'highlightSentence',
            sentence,
            nodeID,
        };
        sendMessageToIframe(message);
    }, [sendMessageToIframe]);


    useEffect(() => {
        if (highlightSentence) {
            const timer = setTimeout(() => {
                sendHighlightSentenceMessage(highlightIDs, highlightSentence);
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [highlightSentence, highlightIDs, sendHighlightSentenceMessage]);

    const handleIframeError = (event) => {
        if (event.response) {
            if (event.response.status === 403) {
                //handleSubscriptionModal();
            } else if (event.response.status === 401) {
                navigate('/login', { state: { redirectPath: `/Company/-/transcript/${fid}` } });
            }
        }
        console.error('Error loading iframe:', event);
    };

    useEffect(() => {
        let url = `${API_URL}` + proxyUrl + filingDoc;
        if (filingDoc.startsWith("/api/transcripts/")) {
            url = `${API_URL}` + filingDoc;
        }
        setIframeSrc(url);
    }, [filingDoc, proxyUrl]);

    useEffect(() => {
        const iframe = iframeRef.current;
        if (!iframe) return;

        const onLoad = () => {
            setIframeReady(true);

            const message = {
                type: 'parentUrl',
                url: window.location.href,
                backend: API_URL + '/api/users/highlight',
                token: currentUser?.token || null
            };
            iframe.contentWindow.postMessage(message, '*');

            if (window.location.hash) {
                iframe.src = iframeSrc + window.location.hash;
            }

            const urlParams = new URLSearchParams(window.location.search);
            const hlStart = urlParams.get('hl_start');
            const hlEnd = urlParams.get('hl_end');


            if (hlStart && hlEnd) {
                console.log("hlStart: " + hlStart)

                iframe.contentWindow.postMessage({ type: 'highlightParams', hlStart, hlEnd }, '*');
            }

            const chlStart = urlParams.get('chl_start');
            const chlEnd = urlParams.get('chl_end');

            if (chlStart && chlEnd) {
                console.log("chlStart: " + chlStart)

                iframe.contentWindow.postMessage({ type: 'highlightContextParams', chlStart, chlEnd }, '*');
            }

            const divStart =urlParams.get('d_start');
            const divEnd =urlParams.get('d_end');
            if (divStart && divEnd) {
                iframe.contentWindow.postMessage({ type: 'handleHighlightDivParams', divStart, divEnd }, '*');
            }

            const sStart =urlParams.get('s_start');
            const sEnd =urlParams.get('s_end');
            if (sStart && sEnd) {
                iframe.contentWindow.postMessage({ type: 'highlightBetweenSentencesParams', sStart, sEnd }, '*');
            }

            const eStart =urlParams.get('e_start');
            const eEnd =urlParams.get('e_end');
            const hlText = urlParams.get('text');

            if (eStart && eEnd) {
                iframe.contentWindow.postMessage({ type: 'handleHighlightElementParams', eStart, eEnd, hlText }, '*');
            }
        };

        iframe.addEventListener('load', onLoad);

        return () => {
            iframe.removeEventListener('load', onLoad);
        };
    }, [currentUser, iframeSrc]);

    useEffect(() => {
        if (iframeReady) {
            sendMessageToIframe({ type: 'currentUser', currentUser: currentUser || null });
            var exhibitData = {
                is_transcript: false,
                document_id: "" + fid + "",
                cik: cik,
                fid: fid,
                eid: eid,
            };
            sendMessageToIframe({ type: 'exhibitData', exhibitData });
        }
    }, [currentUser, iframeReady, sendMessageToIframe, cik, fid, eid]);

    useEffect(() => {
        if (highlightSentence) {
            sendHighlightSentenceMessage(highlightIDs, highlightSentence);
        }
    }, [highlightSentence, highlightIDs, sendHighlightSentenceMessage]);


    return (
        <div className={`iframe-container ${!menuVisible ? "menu-hidden" : ""} ${isDarkMode ? 'dark-mode' : ''}`} style={style}>
            <iframe
                allow="clipboard-write"
                src={iframeSrc}
                title="Filing Content"
                onError={handleIframeError}
                ref={iframeRef}
                className={isDarkMode ? 'white-background' : ''}
            ></iframe>
        </div>
    );
};

export default SECFilingIFrame;