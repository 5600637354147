import React, { useState, useEffect } from "react";
import axiosInstance from '../../../../shared/services/api';
import { PortfolioColumn } from "../../../../features/portfolio/components/column";
import { API_URL } from "../../../../config";

function RecentFilings({ portfolioName, n = 150 } ) {
    const [portfolio, setPortfolio] = useState(null);

    useEffect(() => {
        async function fetchEmptyPortfolioFill() {
            try {
                const res = await axiosInstance.get(`${API_URL}/api/users/portfolio/${portfolioName}?n=${n}`);
                setPortfolio(res.data);
            } catch (error) {
                console.error("Error fetching main portfolio:", error);
            }
        }
        fetchEmptyPortfolioFill();
    }, [portfolioName, n]);


    return (
        <div className="">
            {portfolio && portfolio.filings && <PortfolioColumn filings={portfolio.filings} portfolioName={portfolioName} />}
        </div>
    );
}

export default RecentFilings;