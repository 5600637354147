import React from "react";
import { useTheme } from '../../../../contexts/ThemeContext';

const ArrowTableSearch = ({ onClick }) => {
    const { isDarkMode } = useTheme();

    return (
        <button className={`absolute ${isDarkMode ? 'text-gray-400' : 'text-gray-400'} hover:text-gray-700 top-4 left-4`} onClick={onClick}>
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
        </button>
    );
}

export default ArrowTableSearch;