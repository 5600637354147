import React, { useState, useEffect, useRef, useMemo } from "react";
import axiosInstance from '../../../../shared/services/api';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { API_URL } from "../../../../config";
import FilingCardCompanySearch from '../../../../features/filings/components/cards/FilingCardCompanySearch';
import TableSearchInput from '../text/TableSearchInput';

Modal.setAppElement('#root')

function TableSearchWithModal({ ticker }) {
    const [searchTerm, setSearchTerm] = useState("");
    const [tables, setTables] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const searchBarRef = useRef(null);

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
        setTables([]);
    }

    const handleTableClick = (id) => {
        // Navigate the user to the desired URL when a table is clicked
        navigate(`/tables/${ticker}/${id}`);
        closeModal();
    };

    // Define your own styles for the modal
    const customStyles = useMemo(() => ({
        content: {
            top: '50%',  // Adjust this value to position the modal just below the search bar
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',  // Keeps the modal horizontally centered
            overflowY: 'auto',
            maxHeight: '80vh',
            backgroundColor: '#f9fafb',
            color: 'black',
            width: '98%',
            maxWidth: '900px',
            padding: '10px',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
        }
    }), []);

    useEffect(() => {
        if (searchTerm) {

            axiosInstance
                .get(`${API_URL}/api/tables/search/${ticker}?q=${searchTerm}`)
                .then((response) => {
                    const tables = response.data;
                    setTables(tables);
                    if (tables != null && tables.length > 0) {
                        openModal();
                    } else {
                        closeModal();
                    }
                })
                .catch((error) => {
                    console.error("Error fetching suggestions:", error);
                    setTables([]);  // Set to empty array in case of error
                    closeModal();
                });
        } else {
            setTables([]);
            closeModal(); // Close the modal if search term is empty
        }
    }, [searchTerm, ticker, customStyles]);

    return (
        <div className="flex items-center bg-gray-100 rounded p-2 h-10 relative w-full max-w-4xl mx-auto">

            {/*
            TODO how can I make this text input work well with my other TextInput component?
            */}
            {/*<div className="flex items-center bg-gray-100 rounded-full p-2 h-10 relative w-full max-w-4xl mx-auto">*/}
            {/*    <input*/}
            {/*        type="text"*/}
            {/*        placeholder="Table search by keyword"*/}
            {/*        className="border-none bg-transparent text-gray-600 text-sm px-3 outline-none w-full rounded-md"*/}
            {/*        value={searchTerm}*/}
            {/*        onChange={(e) => setSearchTerm(e.target.value)}*/}
            {/*    />*/}

            {/*</div>*/}


            <TableSearchInput
                query={searchTerm}
                setQuery={setSearchTerm}
                handleSearch={() => {}} // We don't need a separate handleSearch function here
                searchInputRef={searchBarRef}
                placeholder="Table search by keyword"
            />

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Company Table"
                shouldFocusAfterRender={false}
                shouldReturnFocusAfterClose={false}
            >
                {tables.map((table, index) => (
                    <div
                        key={table.id}
                        onClick={() => handleTableClick(table.id)}
                        className="flex flex-col border border-gray-300 rounded px-0 cursor-pointer text-black w-full max-w-4xl bg-gray-50"
                    >
                        <FilingCardCompanySearch filing={table.filing} exhibit={table.exhibit}></FilingCardCompanySearch>
                        <h3 className="m-0 text-black font-bold pl-8 py-4">{table.table_name_llm}</h3>{/**
                     <p className="my-1 text-black">Tags: {table.tags}</p> **/}

                        <div className="flex justify-center" dangerouslySetInnerHTML={{ __html: table.html }} />
                    </div>
                ))}
            </Modal>
        </div>
    );
}

export default TableSearchWithModal;