import React from 'react';
import { NavBarSmall } from '../../../../shared/components/navigation';
import { Footer } from '../../../layout';
import { LandingBackground } from '../../../../features/landing';
import { AboutCenteredDiv } from '../../../../shared/components/ui/layout';

const About = () => {
    return (
        <LandingBackground>
            <NavBarSmall />
            <div className="lg:w-1/2 md:w-4/5 mx-auto">
                <div className="bg-cover bg-center">
                    <div className="p-8 my-20">
                        <AboutCenteredDiv>
                            <div className="text-3xl p-4 font-bold">About Modeledge</div>
                            <p className="p-4 text-md">We aim to design simple, ergonomic research tools for post-LLM, U.S. focused investment research.</p>
                            <p className="p-4 text-md">For more information, please reach out via our <a href="/contact" className="text-blue-500 hover:text-blue-600">contact</a> page.</p>
                            <p className="p-4 text-md">You may also email us directly at customers@modeledge.ai</p>
                        </AboutCenteredDiv>
                    </div>
                </div>
            </div>
            <Footer/>
        </LandingBackground>
    );
};

export default About;