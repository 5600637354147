import { FaTwitter } from 'react-icons/fa';

const FooterSmall = () => {
    return (
        <footer className="bg-nav-bar-theme dark:bg-gray-900 p-3 border-t border-gray-200 dark:border-gray-700 text-xs">
            <div className="container mx-auto flex justify-between">
                <p className="text-teal-300 dark:text-teal-400">
                    &copy; 2024 Modeledge
                </p>

                <div>
                    <a className="text-teal-300 hover:text-teal-500 dark:text-teal-400 dark:hover:text-teal-300 mr-3 transition-colors" href="/about">About</a>
                    <a className="text-teal-300 hover:text-teal-500 dark:text-teal-400 dark:hover:text-teal-300 mr-3 transition-colors" href="/terms">Terms</a>
                    <a className="text-teal-300 hover:text-teal-500 dark:text-teal-400 dark:hover:text-teal-300 mr-3 transition-colors" href="/privacy">Privacy</a>
                </div>

                <div className="text-gray-300 dark:text-gray-400 flex-row flex items-center">
                    News Feed
                <a
                    href="https://twitter.com/FwdQuarter"
                    className="text-gray-300 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-200 mx-2 transition-colors"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaTwitter size={20} />
                </a>
                </div>
            </div>
        </footer>
    );
};

export default FooterSmall;