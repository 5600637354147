import CompanyButton from './CompanyButton';

/**
 * CompanyButtonV4 - A wrapper component for backward compatibility
 * Uses the consolidated CompanyButton with the 'compact' variant
 */
const CompanyButtonV4 = (props) => {
    return <CompanyButton {...props} variant="compact" />;
};

export default CompanyButtonV4;