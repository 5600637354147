import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export function useSubscriptionModal() {
    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
    const navigate = useNavigate();

    const handleSubscriptionModal = () => {
        setShowSubscriptionModal(true);
    };

    const handleSubscriptionModalClose = () => {
        setShowSubscriptionModal(false);
        navigate('/subscriptions');
    };

    return {
        showSubscriptionModal,
        handleSubscriptionModal,
        handleSubscriptionModalClose,
    };
}

export default useSubscriptionModal;