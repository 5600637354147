import React from 'react';
import { SearchIcon } from '@heroicons/react/outline';

const TextSearchInput = ({ query, setQuery, handleSearch, searchInputRef, placeholder }) => {
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <div className="flex items-center bg-gray-100 rounded-full p-2 h-10 relative w-full max-w-4xl mx-auto">
            <input
                ref={searchInputRef}
                type="text"
                placeholder={placeholder}
                className="w-full border-none bg-transparent text-gray-600 text-sm px-3 outline-none rounded-full"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={handleKeyDown}
            />
            <button
                onClick={handleSearch}
                className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-600 transition-colors duration-200"
                aria-label="Execute search"
            >
                <SearchIcon className="h-4 w-4" />
            </button>
        </div>
    );
};

export default TextSearchInput;