import React from 'react';
import { useLocation } from 'react-router-dom';
import Auth from './Auth';

// Importing from shared components
import { NavBarSmall } from '../../../shared/components/navigation';
import { Footer } from '../../layout';
import { BackgroundWrapper } from '../../layout';

function Login() {
    const location = useLocation();
    const customRedirect = location.state?.redirectPath || '/portfolio';

    return (
        <div className="bg-background-cover-theme bg-cover bg-center min-h-screen flex flex-col justify-between">
            <NavBarSmall />
            <BackgroundWrapper>
                <Auth redirectPath={customRedirect} />
            </BackgroundWrapper>
            <Footer/>
        </div>
    )
}

export default Login;