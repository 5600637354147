import React from 'react';

/**
 * Button component with standard styling
 * 
 * @param {Object} props - Component props
 * @param {ReactNode} props.children - Button content
 * @param {string} props.className - Additional CSS classes
 * @returns {JSX.Element} Styled button component
 */
const Button = ({ children, className, ...props }) => {
    return (
        <button
            className={`px-3 py-1 bg-neutral-900 dark:bg-neutral-700 text-white rounded hover:bg-neutral-600 dark:hover:bg-neutral-600 focus:outline-none focus:ring-2 focus:ring-neutral-500 dark:focus:ring-neutral-400 focus:ring-opacity-50 ${className}`}
            {...props}
        >
            {children}
        </button>
    );
};

export default Button;