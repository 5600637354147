import React from "react";
import { Arrow } from "../../../../shared/components/ui";
import { CompanyButton } from "../../../../features/company/components/buttons";
import { Logo } from "../../../../shared/components/branding";
import { TranscriptMenuSecondaryData } from "./";

/**
 * TranscriptMenu Component
 * Displays the navigation menu for transcripts including company information 
 * and transcript details.
 */
const TranscriptMenu = ({
                        ticker,
                        type,
                        transcriptDetails,
                        menuVisible,
                        toggleMenu,
                    }) => {

    if (!transcriptDetails) {
        return <div className={`relative transition-transform duration-100 z-5 overflow-y-auto h-screen ${menuVisible ? '' : 'translate-x-[-100%+40px]'}`}>
            <Arrow onClick={toggleMenu} />
            <div className="font-sans bg-gray-100 dark:bg-gray-800 overflow-y-auto h-full w-80">
                <div className="flex justify-center no-underline">
                    <h1 className="font-nunito text-black dark:text-white mr-6 ml-2 my-4 mb-5 pt-1 text-3xl font-semibold font-sans">
                        <Logo />
                    </h1>
                </div>
                <div className={"rounded-sm mx-3 mb-2"}>
                    <CompanyButton company={""} ticker={""} />
                    <TranscriptMenuSecondaryData />
                </div>
            </div>
        </div>
    }

    return (
        <div className={`relative transition-transform duration-100 z-5 overflow-y-auto h-screen ${menuVisible ? '' : 'translate-x-[-100%+40px]'}`}>
            <Arrow onClick={toggleMenu} />
            <div className="font-sans bg-gray-100 dark:bg-gray-800 overflow-y-auto h-full w-80">
                <div className="flex justify-center no-underline">
                    <h1 className="font-nunito text-black dark:text-white mr-6 ml-2 my-4 mb-5 pt-1 text-3xl font-semibold font-sans">
                        <Logo />
                    </h1>
                </div>
                <div className={"rounded-sm mx-3 mb-2"}>
                    <CompanyButton company={transcriptDetails.company} ticker={ticker} />
                    <TranscriptMenuSecondaryData transcript_details={transcriptDetails} />
                </div>
            </div>
        </div>
    );
};

export default TranscriptMenu;