import React from 'react';
import { SearchIcon } from '@heroicons/react/outline';

/**
 * Consolidated TextSearchInput component with support for multiple variants
 * 
 * @param {Object} props - Component props
 * @param {string} props.query - Current search query
 * @param {Function} props.setQuery - Function to update search query
 * @param {Function} props.handleSearch - Function to execute search
 * @param {React.RefObject} props.searchInputRef - Ref for the input element
 * @param {string} [props.placeholder] - Placeholder text for the input
 * @param {string} [props.variant] - Component variant (default, v1, v2, v3)
 * @param {string} [props.className] - Additional CSS classes
 * @returns {JSX.Element} TextSearchInput component
 */
const TextSearchInput = ({ 
    query, 
    setQuery, 
    handleSearch, 
    searchInputRef, 
    placeholder = 'Search...',
    className = ''
}) => {
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <div className={`relative mb-1 ${className}`}>
            <div className="relative">
                <input
                    ref={searchInputRef}
                    type="text"
                    placeholder={placeholder}
                    className="w-full pr-10 pl-4 py-2.5 text-sm text-gray-800 dark:text-gray-200 bg-gray-100 dark:bg-gray-700 border-b border-gray-300 dark:border-gray-600 focus:outline-none focus:border-neutral-500 dark:focus:border-gray-400 focus:ring-0 focus:ring-neutral-500 dark:focus:ring-gray-400 transition-colors duration-200"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <button
                    onClick={handleSearch}
                    className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-600 dark:text-gray-300 dark:hover:text-gray-100 transition-colors duration-200"
                    aria-label="Execute search"
                >
                    <SearchIcon className="h-4 w-4" />
                </button>
            </div>
        </div>
    );
};

export default TextSearchInput;